import Translation from '@/client/components/Translation';
import GenericAlarm from '@/external/icons/GenericAlarm';

type Props = {
  titleTranslateId?: string;
  bodyTranslateId?: string;
  bodyTranslateValues?: { [key: string]: string };
  translations: Record<string, string>;
};

const WarningBanner: FC<Props> = ({
  titleTranslateId,
  bodyTranslateId,
  children,
  translations,
  bodyTranslateValues,
}) => (
  <div className="grid h-max grid-flow-col grid-cols-[min-content_auto] items-center gap-3 rounded-moon-s-md bg-goku p-3">
    <div className="grid h-10 w-10 grid-flow-col items-center justify-items-center gap-4 rounded-moon-s-md">
      <GenericAlarm />
    </div>
    <div className="grid">
      {titleTranslateId && (
        <p className="text-moon-14 font-semibold text-krillin">
          <Translation id={titleTranslateId} translations={translations} />
        </p>
      )}
      {bodyTranslateId && (
        <p className="text-moon-14 text-bulma">
          <Translation
            id={bodyTranslateId}
            values={bodyTranslateValues}
            translations={translations}
          />
        </p>
      )}
      {children}
    </div>
  </div>
);

export default WarningBanner;
