'use client';
import Content from './Content';
import useDataSolutionsNotification from './useDataSolutionsNotification';
import handleMarkAsRead from '../graphql/handleMarkAsRead';
import Link from '@/client/components/Link';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';
import getThemeClassName from '@/shared/utils/getThemeClassName';

const DataSolutionsNotification: FC<NotificationViewProps> = ({
  notification,
  context,
  onClose,
}) => {
  const { translations, title, body, link, action } =
    useDataSolutionsNotification(notification);
  if (!translations) {
    return null;
  }
  const handleClose = () => {
    handleMarkAsRead(notification.data);
    onClose();
  };
  return (
    <div
      data-testid="data-solutions-notification"
      className={getThemeClassName(context.site, 'light')}
    >
      <Content onClose={handleClose}>
        <div className="grid grid-flow-col grid-cols-[1fr_auto] items-center gap-3 pt-6 text-left">
          <div className="grid gap-4">
            <h4 className="text-moon-18 font-medium text-popo">
              <Translation translations={translations} id={title} />
            </h4>
            <p className="text-moon-14">
              <Translation translations={translations} id={body} />
            </p>
            {link && (
              <Link href={link}>
                <Button fullWidth>
                  <Translation translations={translations} id={action} />
                </Button>
              </Link>
            )}
          </div>
        </div>
      </Content>
    </div>
  );
};

export default DataSolutionsNotification;
