'use client';
import useSwitchCurrencyNotification from './hooks/useSwitchCurrencyNotification';
import Content from '../Content';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';

const SwitchCurrencyNotification: FC<NotificationViewProps> = (props) => {
  const { onClose, context } = props;
  const { handleCurrencyChange, values } = useSwitchCurrencyNotification(props);
  return (
    <Content onClose={onClose} testId="switch-currency-notification">
      <div className="grid cursor-pointer gap-2 pt-6 ltr:text-left rtl:text-right">
        <h4 className="text-moon-18 text-popo">
          <Translation
            translations={context.translations}
            id="casino.switch_currency_to_play_game"
          />
        </h4>
        <p className="text-trunks">
          <Translation
            translations={context.translations}
            id="casino.in_order_to_play_game"
            values={values}
          />
        </p>
        <div className="grid grid-flow-col grid-cols-[50%_50%] justify-between gap-4">
          <Button variant="primary" onClick={handleCurrencyChange}>
            <Translation
              translations={context.translations}
              id="button.switch"
            />
          </Button>
          <Button variant="tertiary" onClick={onClose}>
            <Translation translations={context.translations} id="general.no" />
          </Button>
        </div>
      </div>
    </Content>
  );
};

export default SwitchCurrencyNotification;
