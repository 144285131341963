import { useCallback } from 'react';
import parseNotificationLink from './helpers/parseNotificationLink';
import configs from '@/server/helpers/getSiteConfig/configs';
import nativeRedirect from '@/shared/utils/router/nativeRedirect';

type Props = {
  context: WorkerContext;
  onClose: () => void;
  notification?: NotificationFragment;
};

const useHandleNotificationClick = ({
  notification,
  onClose,
  context,
}: Props) => {
  const { language, site } = context;
  const { isBridgeSite, defaultLang } = configs[site];
  const handleCloseNotificationClick = useCallback(async () => {
    if (!notification) {
      return;
    }
    onClose();
  }, [notification, onClose]);

  const handleNotificationClick = useCallback(async () => {
    if (!notification) {
      return;
    }

    if (
      notification.link?.includes('#deposit') ||
      notification.link?.includes('#withdraw')
    ) {
      window.dispatchEvent(new CustomEvent('openCashier'));
      return;
    }

    const parsedLink = parseNotificationLink(notification.link, isBridgeSite);
    nativeRedirect(parsedLink, language, defaultLang);
    onClose();
  }, [defaultLang, isBridgeSite, language, notification, onClose]);
  return { handleNotificationClick, handleCloseNotificationClick };
};

export default useHandleNotificationClick;
