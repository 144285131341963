import useCodeEntering from './hooks/useCodeEntering';
import Dialog from '../../Dialog';
import Translation from '@/client/components/Translation';
import AuthCode from '@/external/components/AuthCode';
import Button from '@/external/components/Button';
import Hint from '@/external/components/Hint';

type Props = {
  onSubmit: () => void;
  phoneNumber: string;
  onMoreOptionsClick: () => void;
  onClose: () => void;
  title: string;
  onClickBack: () => void;
  translations: Record<string, string>;
  isMobile: boolean;
};

const AuthCodeDialog: FC<Props> = ({
  onSubmit,
  phoneNumber,
  onMoreOptionsClick,
  onClose,
  title,
  onClickBack,
  translations,
  isMobile,
}) => {
  const { errorMessage, handleChange } = useCodeEntering(
    onSubmit,
    translations,
  );
  return (
    <Dialog
      onClose={onClose}
      title={title}
      onClickBack={onClickBack}
      translations={translations}
      isMobile={isMobile}
    >
      <div className="grid gap-4">
        <p className="text-moon-14 text-trunks">
          <Translation
            id="profile.enter_code_from_sms"
            values={{ phone: phoneNumber }}
            translations={translations}
          />
        </p>
        <AuthCode onChange={handleChange} isValid={!errorMessage} />
        <Hint error className="!ps-0">
          {errorMessage}
        </Hint>
        <div className="flex flex-row items-center gap-1">
          <p className="text-moon-14 text-trunks">
            <Translation
              id="profile.did_not_get_code"
              translations={translations}
            />
          </p>
          <Button
            onClick={onMoreOptionsClick}
            size="sm"
            data-testid="more-options-button"
            className="static bg-transparent p-0 text-bulma"
          >
            <p className="text-moon-14 underline">
              <Translation
                id="profile.more_options"
                translations={translations}
              />
            </p>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AuthCodeDialog;
