import getTimeLeft from './getTimeLeft';
import useMillisecondsRemainingInterval from './useMillisecondsRemainingInterval';

const useTimer = (options: TimerOptions) => {
  const timeLeftInMillis = useMillisecondsRemainingInterval(options);
  const timeLeft = getTimeLeft(options.format, timeLeftInMillis);
  return timeLeft;
};

export default useTimer;
