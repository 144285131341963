import Button from '@/external/components/Button';
import ControlsClose from '@/external/icons/ControlsClose';

type Props = {
  onClose: () => void;
};

const CloseButton: FC<Props> = ({ onClose }) => (
  <Button
    className="absolute top-2 float-right cursor-pointer justify-self-end border-none bg-transparent "
    onClick={onClose}
  >
    <ControlsClose className="text-gohan" fontSize={24} />
  </Button>
);

export default CloseButton;
