'use client';
import NftRewardNotificationView from './NftRewardNotificationView';
import ToastContextProvider from '@/client/components/Toast/Context/ToastContextProvider';
import isLoggedIn from '@/server/helpers/isLoggedIn';

type Props = {
  notification: WorkerNotification;
  context: WorkerContext;
  onClose: () => void;
};

const NftRewardNotification: FC<Props> = ({
  notification,
  onClose,
  context,
}) => {
  if (!isLoggedIn()) {
    return null;
  }
  return (
    <ToastContextProvider>
      <NftRewardNotificationView
        notification={{ normalNotification: notification.data }}
        onClose={onClose}
        context={context}
      />
    </ToastContextProvider>
  );
};

export default NftRewardNotification;
