/* eslint-disable immutable/no-mutation */
'use client';
import { useEffect } from 'react';
import { UserManagement } from '@/schema';

type Props = {
  user: UserManagement['currentUser'];
  profile: UserManagement['currentProfile'];
  locale: LanguageLocale;
  isInsider: boolean;
  isVip?: boolean;
  refAff?: string;
  refCode?: string;
  cid?: string;
};

const SetUserGtmInfo: FC<Props> = ({
  user,
  profile,
  locale,
  isInsider,
  isVip,
  refAff,
  refCode,
  cid,
}) => {
  useEffect(() => {
    global.GTM = {
      user: {
        email: profile?.email,
        userId: profile?._id,
        username: user?.username,
        affiliateID: profile?.affiliateID,
      },
      isInsider,
      isLoggedIn: !!user?.id,
      isVip,
      locale,
      marketing: {
        cid,
        refAff,
        refCode,
      },
    };
  }, [
    cid,
    isInsider,
    profile?.affiliateID,
    isVip,
    locale,
    profile?._id,
    profile?.email,
    refAff,
    refCode,
    user?.id,
    user?.username,
  ]);

  return null;
};

export default SetUserGtmInfo;
