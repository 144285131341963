'use client';
import Close from './Close';
import classNames from '@/external/classNames';

type Props = {
  onClose: () => void;
  hiddenCloseBtn?: boolean;
  className?: string;
  testId?: string;
};

const Content: FC<Props> = ({
  onClose,
  hiddenCloseBtn,
  children,
  className,
  testId,
}) => (
  <div
    data-testid={testId}
    className={classNames(
      'relative grid w-[stretch] gap-4 rounded-moon-s-sm bg-goten px-8 pb-8 text-center shadow-moon-xl md:w-80',
      className,
    )}
  >
    {!hiddenCloseBtn && <Close onClose={onClose} />}
    {children}
  </div>
);

export default Content;
