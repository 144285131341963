// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../schema';

import fetchGraphql from '@/server/helpers/fetchQuery';
import gql from '@/shared/utils/gql';
export type CmsPageQueryVariables = Types.Exact<{
  filter: Types.ContentPageConnectionFilter;
}>;


export type CmsPageQuery = { __typename?: 'Query', content?: { __typename?: 'Content', id: string, pages?: { __typename?: 'ContentPageConnection', totalCount?: number, edges?: Array<{ __typename?: 'ContentPageEdge', node?: { __typename: 'ContentPage', id: string, name?: string, mainBlock?: string, mainBlockPlainText?: string, handle?: string, sectionHandle?: string, participating?: boolean, smallImage?: string, mediumImage?: string, largeImage?: string, terms?: string, title?: string, summary?: string, paths?: string, link?: string } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string, hasNextPage: boolean } } } };

export type CmsPageFragment = { __typename: 'ContentPage', id: string, name?: string, mainBlock?: string, mainBlockPlainText?: string, handle?: string, sectionHandle?: string, participating?: boolean, smallImage?: string, mediumImage?: string, largeImage?: string, terms?: string, title?: string, summary?: string, paths?: string, link?: string };

export type CmsNodeFragment = { __typename?: 'ContentPageEdge', node?: { __typename: 'ContentPage', id: string, name?: string, mainBlock?: string, mainBlockPlainText?: string, handle?: string, sectionHandle?: string, participating?: boolean, smallImage?: string, mediumImage?: string, largeImage?: string, terms?: string, title?: string, summary?: string, paths?: string, link?: string } };

export const CmsPageFragmentDoc = gql`
    fragment CmsPageFragment on ContentPage {
  id
  name
  mainBlock
  mainBlockPlainText
  handle
  sectionHandle
  participating
  smallImage
  mediumImage
  largeImage
  terms
  title
  summary
  paths
  link
  __typename
}
    `;
export const CmsNodeFragmentDoc = gql`
    fragment CmsNodeFragment on ContentPageEdge {
  node {
    ...CmsPageFragment
  }
}
    ${CmsPageFragmentDoc}`;
export const CmsPageQueryDocument = gql`
    query CmsPageQuery($filter: ContentPageConnectionFilter!) {
  content {
    id
    pages(filter: $filter) {
      edges {
        ...CmsNodeFragment
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
}
    ${CmsNodeFragmentDoc}`;

export const getCmsPageQuery = async (variables: CmsPageQueryVariables) => {
  return fetchGraphql<CmsPageQuery, CmsPageQueryVariables>(CmsPageQueryDocument, "CmsPageQuery", { variables });
}