import getCountryCallingCode from './getCountryCallingCode';

const formatPhoneCallingCode = ({
  callingCode,
  country,
  phone,
}: {
  callingCode: string;
  country: string;
  phone: string;
}) => {
  const code = getCountryCallingCode(country) || '';
  if (callingCode && code) {
    return { phone: phone.replace(`+${code}`, ''), code };
  }
  return { phone, code: callingCode };
};

export default formatPhoneCallingCode;
