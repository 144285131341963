import AppAuth from './AppAuth';
import AuthCodeInput from './AuthCodeInput';
import useEnable2FA from './hooks/useEnable2FA';
import Dialog from '../Dialog';
import Translation from '@/client/components/Translation';
import classNames from '@/external/classNames';
import Button from '@/external/components/Button';

type Props = {
  onClose: () => void;
  onClickBack?: () => void;
  site: Site;
  domain: string;
  translations: Record<string, string>;
  isMobile: boolean;
};

const EnableAppAuthDialog: FC<Props> = ({
  onClose,
  onClickBack,
  site,
  domain,
  translations,
  isMobile,
}) => {
  const { isError, otpKey, handleSubmit, inputValue, handleChange } =
    useEnable2FA(translations, onClose);
  const isBitcasino = site === 'bitcasino';

  return (
    <Dialog
      onClose={onClose}
      translations={translations}
      isMobile={isMobile}
      onClickBack={onClickBack}
      title={'profile.app_authentication'}
    >
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4">
          <p className="text-trunks">
            <Translation
              id="general.scan_code_or_enter"
              translations={translations}
            />
          </p>
          <AppAuth
            site={site}
            domain={domain}
            otpKey={otpKey}
            translations={translations}
          />
          <AuthCodeInput
            onChange={handleChange}
            translations={translations}
            errors={isError}
            value={inputValue}
          />
          <Button
            className={classNames(isBitcasino && 'text-goku')}
            variant="primary"
            fullWidth
            size="lg"
            data-testid="enable-app-auth-button"
            onClick={handleSubmit}
          >
            <Translation
              id="profile.enable_app_authentication"
              translations={translations}
            />
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default EnableAppAuthDialog;
