import getPhoneNumber, {
  parseIncompletePhoneNumber,
  PhoneNumber,
} from 'libphonenumber-js';

const parsePhoneNumber = (userPhone: string): PhoneNumber | undefined => {
  const completedPhone = parseIncompletePhoneNumber(userPhone);
  const phoneDetails = getPhoneNumber(completedPhone);
  return phoneDetails;
};

export default parsePhoneNumber;
