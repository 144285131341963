// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type RewardsNftDropClaimRewardMutationVariables = Types.Exact<{
  input: Types.RewardsNftDropClaimRewardInput;
}>;


export type RewardsNftDropClaimRewardMutation = { __typename?: 'Mutation', rewardsNftDropClaimReward?: { __typename?: 'RewardsNftDropClaimRewardPayload', success?: boolean, errors?: Array<{ __typename?: 'RewardsNftDropError', message?: string, code?: number }> } };


export const RewardsNftDropClaimRewardMutationDocument = gql`
    mutation RewardsNftDropClaimRewardMutation($input: RewardsNftDropClaimRewardInput!) {
  rewardsNftDropClaimReward(input: $input) {
    success
    errors {
      message
      code
    }
  }
}
    `;

export const getRewardsNftDropClaimRewardMutation = async (variables: RewardsNftDropClaimRewardMutationVariables) => {
  return fetchGraphql<RewardsNftDropClaimRewardMutation, RewardsNftDropClaimRewardMutationVariables>(RewardsNftDropClaimRewardMutationDocument, "RewardsNftDropClaimRewardMutation", { variables });
}