import { useState } from 'react';

const use2faModes = (initialMode?: Mode, isSms2faSwitcherEnabled?: boolean) => {
  const [mode, setMode] = useState<Mode>(initialMode || 'methodSelection');

  const showMethodSelection = () => setMode('methodSelection');
  const showApp2fa = () => setMode('app2fa');
  const showSms2fa = () => setMode('sms2fa');
  const showApp2faDisable = () => setMode('app2faDisable');
  const showSms2faDisable = () => setMode('sms2faDisable');
  const handleClickBack = isSms2faSwitcherEnabled
    ? showMethodSelection
    : undefined;

  return {
    mode,
    showMethodSelection,
    showApp2fa,
    showSms2fa,
    showApp2faDisable,
    showSms2faDisable,
    handleClickBack,
  };
};

export default use2faModes;
