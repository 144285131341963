import { useEffect } from 'react';
import parse from 'html-react-parser';
import classNames from '@/external/classNames';
import Img from '@/shared/components/Img';

type Props = {
  notification: WorkerNotification;
  context: WorkerContext;
  onClose: () => void;
};

const PopupContent: FC<Props> = ({ notification, context, onClose }) => {
  const { mainBlock, largeImage: imageUrl, title } = notification.data;
  const isMobile = context.deviceType !== 0;
  const text = parse(mainBlock || '');

  useEffect(() => {
    const handleClick = () => onClose();
    const buttonAction = document.querySelector('.redirect-button');

    buttonAction?.addEventListener('click', handleClick);
    return () => buttonAction?.removeEventListener('click', handleClick);
  }, [onClose]);

  return (
    <>
      <Img
        src={imageUrl}
        height={isMobile ? 146 : 260}
        width={isMobile ? 352 : 622}
        alt=""
        className="rounded-xl transition"
      />
      <h3 className="pt-6 pb-2 px-10 text-moon-32 font-medium text-center text-bulma">
        {title}
      </h3>
      <div
        className={classNames(
          'text-trunks text-center',
          '[&_iframe]:max-w-full [&_table]:text-base [&_table]:max-w-full [&_table]:block',
          '[&_img]:inline [&_p]:text-base',
          '[&_ol]:list-decimal [&_ol]:text-base [&_ol]:[margin-block:1rem] [&_ol]:[margin-inline:0] [&_ol]:[padding-inline-start:2.5rem]',
          '[&_ul]:list-disc [&_ul]:text-base [&_ul]:[margin-block:1rem] [&_ul]:[margin-inline:0] [&_ul]:[padding-inline-start:2.5rem]',
          '[&_button]:text-sm [&_button]:bg-piccolo [&_button]:text-gohan [&_button]:rounded-md [&_button]:h-10 [&_button]:px-4 [&_button]:mt-2',
          '[&_button]:inline-flex [&_button]:items-center [&_button]:justify-center ',
        )}
      >
        {text}
      </div>
    </>
  );
};

export default PopupContent;
