// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type UpdateABTestCaseMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateABTestCaseMutation = { __typename?: 'Mutation', testCasesUpdateABTestData?: { __typename?: 'TestCasesUpdateABTestDataPayload', success?: boolean, errors?: Array<{ __typename?: 'TestCasesError', code?: number, message?: string }> } };


export const UpdateABTestCaseMutationDocument = gql`
    mutation UpdateABTestCaseMutation {
  testCasesUpdateABTestData(input: {}) {
    success
    errors {
      code
      message
    }
  }
}
    `;

export const getUpdateABTestCaseMutation = async (variables: UpdateABTestCaseMutationVariables) => {
  return fetchGraphql<UpdateABTestCaseMutation, UpdateABTestCaseMutationVariables>(UpdateABTestCaseMutationDocument, "UpdateABTestCaseMutation", { variables });
}