import DialogBody from './DialogBody';
import Header from './Header';
import Translation from '@/client/components/Translation';
import BottomSheet from '@/external/components/BottomSheet';
import Modal from '@/external/components/Modal';
import ControlsChevronLeft from '@/external/icons/ControlsChevronLeft';
import ControlsClose from '@/external/icons/ControlsClose';

type Props = {
  onClose: () => void;
  title?: string;
  onClickBack?: () => void;
  translations: Record<string, string>;
  isMobile: boolean;
};

const Dialog: FC<Props> = ({
  children,
  onClose,
  title,
  onClickBack,
  translations,
  isMobile,
}) => {
  const backButton = onClickBack ? (
    <div onClick={onClickBack} className="w-4 h-4">
      <ControlsChevronLeft data-testid="back-button" />
    </div>
  ) : undefined;
  const header = (
    <Header
      closeButton={
        <ControlsClose
          className="text-moon-16"
          onClick={onClose}
          data-testid="close-button"
        />
      }
      backButton={backButton}
    >
      {title && <Translation id={title} translations={translations} />}
    </Header>
  );
  return (
    <>
      <BottomSheet open={isMobile} onClose={onClose}>
        <BottomSheet.Backdrop />
        <BottomSheet.Panel className="h-auto">
          {header}
          <DialogBody>{children}</DialogBody>
        </BottomSheet.Panel>
      </BottomSheet>

      <Modal onClose={onClose} open={!isMobile}>
        <Modal.Backdrop />
        <Modal.Panel className="max-w-md">
          {header}
          <DialogBody>{children}</DialogBody>
        </Modal.Panel>
      </Modal>
    </>
  );
};

export default Dialog;
