'use client';
import Script from 'next/script';

type Props = {
  analyticsId?: string;
  token: string;
  site: Site;
  cashierUrl: string;
};

const CashierAgentScript: FC<Props> = ({
  analyticsId,
  token,
  cashierUrl,
  site,
}) => (
  <Script
    id="cashier-agent-script"
    strategy="lazyOnload"
    src={`${cashierUrl}/dist/cashier-agent.js`}
    onLoad={() => {
      const cashierAgent = global.CashierAgent;
      if (typeof cashierAgent === 'function') {
        cashierAgent({ token, analyticsId, brand: site });
      }
    }}
  />
);

export default CashierAgentScript;
