import { ChangeEventHandler, forwardRef } from 'react';
import Translation from '@/client/components/Translation';
import Hint from '@/external/components/Hint';
import Input from '@/external/components/Input';

type Props = {
  errors?: boolean;
  translations: Record<string, string>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
};

const AuthCodeInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { errors = false, translations, ...inputProps } = props;
  return (
    <div>
      <Input
        placeholder={translations['cashier.enter_otp']}
        autoComplete="off"
        error={errors}
        type="tel"
        ref={ref}
        {...inputProps}
      />
      {errors && (
        <Hint className="text-piccolo">
          <Translation
            id="error.invalid_username_password_or_otp"
            translations={translations}
          />
        </Hint>
      )}
    </div>
  );
});

export default AuthCodeInput;
