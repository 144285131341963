import classNames from '@/external/classNames';

type Props = { secondary?: boolean };

const Box: FC<Props> = ({ secondary, children }) => (
  <div
    className={classNames(
      'flex h-16 w-full flex-col items-center justify-center rounded-moon-s-md bg-goku rem:min-w-[128px]',
      secondary && 'bg-gohan/10',
    )}
  >
    {children}
  </div>
);

export default Box;
