import usePhoneFields from './hooks/usePhoneFields';
import Group from '@/external/components/Group';

type Props = {
  value: string;
  onChange: (phoneString: string) => void;
  translations: Record<string, string>;
  site: Site;
  userCountryCode: string;
};

const PhoneInputs: FC<Props> = ({
  value,
  onChange,
  translations,
  site,
  userCountryCode,
}) => {
  const { selectProps, inputProps, handleCodeChange } = usePhoneFields({
    value,
    onChange,
    translations,
    site,
    userCountryCode,
  });

  return (
    <Group orientation="vertical">
      <Group.FirstSelect
        tabIndex={-1}
        value={selectProps.value}
        onChange={handleCodeChange}
        data-testid="country-code-select"
      >
        {selectProps.options.map((countryCode) => (
          <option key={countryCode.value} value={countryCode.value}>
            {countryCode.label}
          </option>
        ))}
      </Group.FirstSelect>
      <Group.LastInput {...inputProps} />
    </Group>
  );
};

export default PhoneInputs;
