import { useEffect, useRef } from 'react';

type Event = MouseEvent | TouchEvent;

type Args = {
  handler: (event?: Event) => void;
  allowCommsClick?: boolean;
};

const useOnClickOutside = <T extends HTMLElement = HTMLElement>({
  handler,
  allowCommsClick,
}: Args) => {
  const elementRef = useRef<T>(null);
  useEffect(() => {
    const listener = (event: Event) => {
      const el = elementRef.current;
      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }
      const commsEl = document.getElementById('comms');
      if (allowCommsClick && commsEl?.contains(event.target as Node)) {
        return;
      }
      const walletConnectModalEl =
        document.getElementsByTagName('wcm-modal')[0];
      if (walletConnectModalEl.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);
    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [handler, allowCommsClick]);
  return { elementRef };
};

export default useOnClickOutside;
