import getCdnMoonIcon from '@/shared/cdn/getCdnMoonIcon';
import Img from '@/shared/components/Img';

const ShopWallet = () => (
  <Img
    src={getCdnMoonIcon('icons/ShopWallet.png')}
    alt=""
    width={24}
    height={24}
    dpr={2}
  />
);

export default ShopWallet;
