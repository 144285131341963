import bitcasino from './bitcasino';
import { CASINO_IMGIX, CDN_IMG_URL } from '@/shared/cdn/url';

const livecasino: SiteConfig = {
  ...bitcasino,
  name: 'Livecasino',
  languages: ['en', 'ja', 'ko', 'vi'],
  domain: 'livecasino.io',
  iconSetCdnPath: `${CDN_IMG_URL}/icons`,
  iconSetExt: '.svg',
  defaultColorScheme: 'dark',
  refCode: 'lcseo',
  fontTypes: ['Averta Std'],
  blogCategories: ['live-dealer', 'casino-tips', 'slots', 'cryptocurrency'],
  privacyPolicyLink:
    '/help-center/help-terms-and-conditions/livecasino-privacy-policy',
  registerButtonText: 'loyalty_landing.play-get-rewards',
  gtmId: 'GTM-WCFZXT3',
  menuItemsConfig: {
    common: {
      first: 4,
      filterName: '^(?!(live|bombay).*$)',
    },
    live: {
      first: 4,
      filterName: '^live',
    },
  },
  commsConfig: {
    productToken: '7CB35FBA9387114EEA8E187CE2DC3B4932B2AD26',
    theme: {
      buttonColor: '#DE0D3E',
      buttonColorActive: '#E61E4D',
      chatBubbleColor: '#DE0D3E',
      chatButtonColorActive: '#E61E4D',
      chatButtonColor: '#DE0D3E',
      notificationColor: '#FFFFFF',
      notificationTextColor: '#0F161F',
    },
  },
  protoConfig: {
    gwId: 'gw_3LWVDxIVZ2xpQBTQqUhyvVL',
  },
  smartlookConfig: {
    isScriptEnabled: false,
    projectId: '',
  },
  convertComConfig: {
    isScriptEnabled: false,
    url: '',
  },
  umamiScript: false,
  icons: {
    icon: [
      {
        url: `https://${CASINO_IMGIX}/bitcasino/images/livecasino/favicon/favicon-32x32.png`,
        sizes: '32x32',
        type: 'image/png',
      },
      {
        url: `https://${CASINO_IMGIX}/bitcasino/images/livecasino/favicon/favicon-16x16.png`,
        sizes: '16x16',
        type: 'image/png',
      },
    ],
    apple: [
      {
        url: `https://${CASINO_IMGIX}/bitcasino/images/livecasino/favicon/apple-touch-icon.png`,
        sizes: '180x180',
      },
    ],
    other: [
      {
        rel: 'mask-icon',
        url: `https://${CASINO_IMGIX}/'bitcasino/images/livecasino/favicon/safari-pinned-tab.svg'`,
        color: '#DE0D3E',
      },
    ],
  },
  themeColor: '#DE0D3E',
  seo: {
    title: 'general.title_livecasino',
    description: 'general.meta_description_livecasino',
    og: {
      title: 'og.title',
      description: 'og.description',
      image: `https://${CASINO_IMGIX}/bitcasino/images/logo-livecasino.jpeg`,
    },
  },
};

export default livecasino;
