import { useCallback, useMemo } from 'react';
import useSetWalletCurrency from './useSetWalletCurrency';
import getCurrencyDisplayIcon from '../../helpers/getCurrencyDisplayIcon';
import { getSetInGameCurrencyMutation } from '../graphql/SetInGameCurrencyMutation.generated';

const useSwitchCurrencyNotification = ({
  onClose,
  context,
  notification,
}: NotificationViewProps) => {
  const { currency = 'USDT' } = notification.data ?? {};
  const walletCurrency = context.currencyConfig.name;
  const { setWalletCurrency } = useSetWalletCurrency(walletCurrency, currency);

  const handleCurrencyChange = useCallback(async () => {
    await getSetInGameCurrencyMutation({
      preferredInGameCurrency: currency,
    });
    await setWalletCurrency();
    window.dispatchEvent(new CustomEvent('currencySwitched'));
    onClose();
  }, [setWalletCurrency, currency, onClose]);

  const isUbtcEnabled = context.currencyConfig.isUbtcEnabled;
  const walletCurrencyIcon = context.currencyConfig.icon;
  const currencyIcon = getCurrencyDisplayIcon(currency, isUbtcEnabled);
  const values = useMemo(
    () => ({
      walletCurrency: walletCurrencyIcon,
      currency: currencyIcon,
    }),
    [walletCurrencyIcon, currencyIcon],
  );
  return { handleCurrencyChange, values };
};

export default useSwitchCurrencyNotification;
