import { useContext } from 'react';
import ToastContext from './ToastContext';

const useToastContext = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error(
      'useToastContext should be used within the ToastContextProvider',
    );
  }
  const { setToastParams } = context;
  const showToast = (props: ToastProps) => {
    setToastParams({ timeout: 3000, position: 'bottom', ...props });
  };

  return { showToast };
};

export default useToastContext;
