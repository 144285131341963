import useCurrencyDisplay from './useCurrencyDisplay';

type Props = {
  amount?: number;
  currency?: CashierCurrency;
  language: LanguageCode;
};

const BoostMoney: FC<Props> = (props) => {
  const { moneyIcon } = useMoney(props);
  return <span>{moneyIcon}</span>;
};

const useMoney = ({ amount = 0, currency, language }: Props) => {
  const { moneyIcon } = useCurrencyDisplay(amount, language, {
    currency,
  });
  return { moneyIcon };
};

export default BoostMoney;
