import { getStartEnablingSMS2FAMutation } from '../../hooks/graphql/StartEnablingSMS2FAMutation.generated';
import useToastContext from '@/client/components/Toast/Context/useToastContext';

const useResendCode = (
  phoneNumber: string,
  onCodeResendSuccess: () => void,
  translations: Record<string, string>,
) => {
  const { showToast } = useToastContext();
  const handleResendCodeClick = async () => {
    const result = await getStartEnablingSMS2FAMutation({
      phone: phoneNumber,
    });
    const { errors: mutationErrors } =
      result.data?.userManagementStartEnablingSMS2FA || {};
    if (mutationErrors?.length) {
      return;
    }
    showToast({ message: translations['profile.new_code_sent'] });
    onCodeResendSuccess();
  };
  return { handleResendCodeClick };
};

export default useResendCode;
