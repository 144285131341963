type Props = {
  children: React.ReactNode;
  closeButton?: React.ReactNode;
  backButton?: React.ReactNode;
};

const Header: FC<Props> = ({ children, closeButton, backButton }) => (
  <div className="flex w-full items-start justify-between border-b border-beerus py-6 px-4 text-moon-18 text-bulma">
    <div className="mr-2 cursor-pointer rem:min-w-[32px]">{backButton}</div>
    <div className="pt-1">{children}</div>
    <div className="flex items-center text-moon-32">
      <div className="ml-2 cursor-pointer">{closeButton}</div>
    </div>
  </div>
);

export default Header;
