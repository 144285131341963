import BoostAmount from './BoostAmount';
import WinAmount from './WinAmount';

type Props = {
  boost: BoostTimerFragment;
  translations: Record<string, string>;
  language: LanguageCode;
};

const Results: FC<Props> = ({ boost, translations, language }) => (
  <div className="grid gap-2">
    <WinAmount boost={boost} translations={translations} language={language} />
    <BoostAmount
      boost={boost}
      translations={translations}
      language={language}
    />
  </div>
);

export default Results;
