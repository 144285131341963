'use client';
import Content from './Content';
import handleMarkAsRead from './graphql/handleMarkAsRead';
import useHandleNotificationClick from './useHandleNotificationClick';
import Img from '@/shared/components/Img';

const NormalNotification: FC<NotificationViewProps> = ({
  notification,
  onClose,
  context,
}) => {
  const handleClose = () => {
    if (notification.type === 'normal') {
      handleMarkAsRead(notification.data);
    }
    onClose();
  };

  const { handleCloseNotificationClick, handleNotificationClick } =
    useHandleNotificationClick({
      context,
      notification: notification.data,
      onClose: handleClose,
    });

  const notificationTitle =
    notification.data?.title?.replaceAll('\u00A0', ' ') ?? '';

  if (!notification.data) {
    return null;
  }

  return (
    <Content
      testId={`${notification.type}-notification`}
      onClose={handleCloseNotificationClick}
    >
      <div
        className="grid cursor-pointer gap-2 pt-6 ltr:text-left rtl:text-right"
        onClick={handleNotificationClick}
      >
        {notification.data.icon && (
          <Img width={48} src={notification.data.icon} alt={''} height={48} />
        )}
        <h4 className="text-moon-18 font-medium text-popo">
          {notificationTitle}
        </h4>
        <p
          className="text-moon-14 text-trunks"
          dangerouslySetInnerHTML={{ __html: notification.data.body ?? '' }}
        />
      </div>
    </Content>
  );
};

export default NormalNotification;
