'use client';
import CloseButton from './CloseButton';
import handleMarkAsRead from '../graphql/handleMarkAsRead';
import useHandleNotificationClick from '../useHandleNotificationClick';
import getCdnIcon from '@/shared/cdn/getCdnIcon';
import Img from '@/shared/components/Img';

const CoinSportNotification: FC<NotificationViewProps> = ({
  notification,
  onClose,
  context,
}) => {
  const handleClose = () => {
    if (notification.type === 'normal') {
      handleMarkAsRead(notification.data);
    }
    onClose();
  };
  const translations = context.translations;

  const notificationData = {
    ...notification.data,
    title: 'notifications.coinsport_reward.title',
    body: 'notifications.coinsport_reward.text',
    link: '/user/rewards/free-bets',
    icon: getCdnIcon('FreebetNotification'),
  };

  const { handleCloseNotificationClick, handleNotificationClick } =
    useHandleNotificationClick({
      context,
      notification: notificationData,
      onClose: handleClose,
    });

  if (!notification.data) {
    return null;
  }

  return (
    <div
      data-testid={`${notification.type}-notification`}
      className="relative w-[stretch] gap-4 rounded-moon-s-sm bg-goten p-4 text-left shadow-moon-xl md:w-98 mb-7 md:mb-0 flex"
    >
      <div
        className="grid grid-flow-col cursor-pointer gap-4 ltr:text-left rtl:text-right "
        onClick={handleNotificationClick}
      >
        {notificationData.icon && (
          <Img width={48} src={notificationData.icon} alt="" height={48} />
        )}
        <div className="grid gap-1">
          <h4 className="text-moon-14 font-medium text-popo">
            {translations[notificationData.title]}
          </h4>
          <p
            className="text-moon-12 text-trunks"
            dangerouslySetInnerHTML={{
              __html: translations[notificationData.body],
            }}
          />
        </div>
      </div>
      <CloseButton onClose={handleCloseNotificationClick} />
    </div>
  );
};

export default CoinSportNotification;
