import { cache } from 'react';
import { getGetCurrentClientLocationMutation } from './CurrentClientLocationMutation.generated';

const getCurrentClientLocation = cache(async () => {
  const { data } = await getGetCurrentClientLocationMutation({});
  const geoData = data?.geolocationGetCurrentClientLocation?.country;
  return geoData;
});

export default getCurrentClientLocation;
