import { useState } from 'react';
import useDidMount from './lifecycle/useDidMount';
import getTranslationsByKeys from '@/actions/getTranslationsByKeys';

const useClientTranslations = (keys: string[]) => {
  const [translations, setTranslations] = useState<Record<string, string>>();
  useDidMount(() => {
    getTranslationsByKeys(keys).then(setTranslations);
  });
  return translations;
};

export default useClientTranslations;
