import bitcasino from './bitcasino';
import { CASINO_IMGIX, CDN_URL } from '@/shared/cdn/url';

const empire: SiteConfig = {
  ...bitcasino,
  name: 'Empire',
  languages: [
    'en',
    'ja',
    'pt',
    'zh',
    'es',
    'ko',
    'th',
    'de',
    'fr',
    'vi',
    'ar',
    'fi',
    'no',
  ],
  domain: 'empire.io',
  refCode: 'emseo',
  privacyPolicyLink:
    '/help-center/help-terms-and-conditions/empire-privacy-policy',
  fontTypes: ['Fira Sans'],
  gtmId: 'GTM-P25PPNC',
  iconSetCdnPath: `${CDN_URL}/empire/images/icons`,
  iconSetExt: '.svg',
  defaultColorScheme: 'dark',
  menuItemsConfig: {
    common: {
      first: 4,
      filterName: '^(?!(live|bombay).*$)',
    },
    live: {
      first: 4,
      filterName: '^live',
    },
  },
  commsConfig: {
    productToken: '044E776655E7A68477858A12E76A39B62E6F4A8C',
    theme: {
      chatButtonColor: '#FF25A8',
      chatButtonColorActive: '#FF25A8',
      buttonColor: '#FF25A8',
      buttonColorActive: '#FF25A8',
      chatBubbleColor: '#FF25A8',
      notificationColor: '#FA4C80',
      notificationTextColor: '#FFFFFF',
    },
  },
  protoConfig: {
    gwId: 'gw_5elne1AeRWoyr881HcdLhoP',
  },
  umamiScript: false,
  smartlookConfig: {
    isScriptEnabled: true,
    projectId: 'c80123dea77280ff2bac28240bc91ead6ba3fe3b',
  },
  convertComConfig: {
    isScriptEnabled: false,
    url: '',
  },
  anjSeal: {
    id: 'faea5734-a1b2-4d25-99f0-7a095d295cd9',
    script:
      'https://faea5734-a1b2-4d25-99f0-7a095d295cd9.snippet.anjouangaming.org/anj-seal.js',
  },
  icons: {
    icon: [
      {
        url: `https://${CASINO_IMGIX}/empire/images/favicons/favicon.ico`,
        sizes: '32x32',
        type: 'image/png',
      },
      {
        url: `https://${CASINO_IMGIX}/empire/images/favicons/favicon-16x16.png`,
        sizes: '16x16',
        type: 'image/png',
      },
    ],
    apple: [
      {
        url: `https://${CASINO_IMGIX}/empire/images/favicons/apple-touch-icon.png`,
        sizes: '180x180',
      },
    ],
    other: [
      {
        rel: 'mask-icon',
        url: `https://${CASINO_IMGIX}/empire/images/favicons/safari-pinned-tab.svg`,
        color: '#FF25A8',
      },
    ],
  },
  themeColor: '#FF25A8',
  seo: {
    title: 'general.title',
    description: 'general.meta_description',
    og: {
      title: 'og.title',
      description: 'og.description',
      image: `https://${CASINO_IMGIX}/bitcasino/images/logo-empire.jpg`,
    },
  },
};

export default empire;
