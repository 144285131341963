'use client';
import getCdnBoostImg from '@/shared/cdn/getCdnBoostImg';
import Img from '@/shared/components/Img';

type Props = { onClose: () => void };

const Close: FC<Props> = ({ onClose }) => (
  <div
    className="absolute top-2 right-2 cursor-pointer"
    data-testid="notification-close-button"
    onClick={onClose}
  >
    <Img
      src={getCdnBoostImg('close-notif-icon')}
      height={24}
      width={24}
      alt=""
    />
  </div>
);

export default Close;
