import ClaimButton from '../ClaimButton';
import Link from '@/client/components/Link';
import Translation from '@/client/components/Translation';

type Props = {
  boost: BoostTimerFragment;
  context: WorkerContext;
  onClose: () => void;
};

const Footer: FC<Props> = ({ boost, context, onClose }) => (
  <div className="mt-3 grid gap-4">
    <ClaimButton
      boost={boost}
      translations={context.translations}
      onClose={onClose}
    />
    <Link
      onClick={onClose}
      className="font-headline text-trunks"
      href="/profile/boosts"
    >
      <Translation
        id="casino.boosts.all_boosts"
        translations={context.translations}
      />
    </Link>
  </div>
);

export default Footer;
