/* eslint-disable immutable/no-mutation */
'use client';
import { useEffect } from 'react';
import initUserGtmEvents from './helpers/initUserGtmEvents';
import sendEventsToAnalytics from './helpers/sendEventsToAnalytics';
import getGraphqlEndpoint from '@/shared/getGraphqlEndpoint';

type Props = { analyticsId?: string; token: string };

const AnalyticsWorkerHandler: FC<Props> = (context) => {
  useEffect(() => {
    initUserGtmEvents();
    const worker = new Worker(new URL('./analyticsWorker', import.meta.url));
    worker.onmessage = (event: MessageEvent) =>
      sendEventsToAnalytics(event.data);
    worker.postMessage({ ...context, endpoint: getGraphqlEndpoint() });
    return () => worker.terminate();
  });

  return null;
};

export default AnalyticsWorkerHandler;
