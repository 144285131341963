import { getClaimBoostMutation } from './ClaimBoostMutation.generated';
import isBoostClaimable from './isBoostClaimable';

const buildVariables = (boostTimerId: string) => ({ input: { boostTimerId } });

const useClaimButton = (boost: BoostTimerFragment) => {
  const handleClick = async () => {
    const variables = buildVariables(boost.id);
    await getClaimBoostMutation(variables);
  };
  const disabled = !isBoostClaimable(boost);
  return { disabled, handleClick };
};

export default useClaimButton;
