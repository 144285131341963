import AuthCodeDialog from './AuthCodeDialog';
import useSmsAuthEnabling from './hooks/useSmsAuthEnabling';
import MoreOptionsDialog from './MoreOptionsDialog';
import PhoneNumberForm from './PhoneNumberForm';
import Dialog from '../Dialog';

type Props = {
  onClose: () => void;
  onClickBack: () => void;
  onUseAppClick: () => void;
  isMobile: boolean;
  translations: Record<string, string>;
  language: string;
  site: Site;
  domain: string;
  countryCode: string;
};

const EnableSmsAuthDialog: FC<Props> = ({
  onClose,
  onClickBack,
  onUseAppClick,
  isMobile,
  translations,
  language,
  site,
  domain,
  countryCode,
}) => {
  const {
    step,
    handleNumberSubmit,
    phoneNumber,
    handleCodeSubmit,
    handleEnterPhoneClick,
    handleEnterCodeClick,
    handleMoreOptionsClick,
  } = useSmsAuthEnabling(onClose, language, translations);

  if (step === 'phone_number') {
    return (
      <Dialog
        onClose={onClose}
        title="profile.sms_authentication"
        onClickBack={onClickBack}
        translations={translations}
        isMobile={isMobile}
      >
        <PhoneNumberForm
          onSubmit={handleNumberSubmit}
          phoneNumber={phoneNumber}
          site={site}
          translations={translations}
          domain={domain}
          countryCode={countryCode}
        />
      </Dialog>
    );
  }

  if (step === 'code') {
    return (
      <AuthCodeDialog
        onClose={onClose}
        title="profile.verify_phone_number"
        onClickBack={handleEnterPhoneClick}
        onSubmit={handleCodeSubmit}
        phoneNumber={phoneNumber}
        onMoreOptionsClick={handleMoreOptionsClick}
        translations={translations}
        isMobile={isMobile}
      />
    );
  }

  if (step === 'more_options') {
    return (
      <MoreOptionsDialog
        onClose={onClose}
        onUseAppClick={onUseAppClick}
        phoneNumber={phoneNumber}
        onCodeResendSuccess={handleEnterCodeClick}
        onClickBack={handleEnterCodeClick}
        onChangePhoneClick={handleEnterPhoneClick}
        translations={translations}
        isMobile={isMobile}
      />
    );
  }

  return null;
};

export default EnableSmsAuthDialog;
