const getCashierLink = (
  pathname: string,
  cashierPath: string,
  isSbio: boolean,
  isCashierEnabled: boolean,
) => {
  if (isSbio && !isCashierEnabled) {
    return '/profile/funds';
  }

  return `${pathname}?c=${cashierPath}`;
};

export default getCashierLink;
