/* eslint-disable immutable/no-mutation */
'use client';
import { useEffect } from 'react';
import closeNotification from './helpers/closeNotification';
import isNotificationClosed from './helpers/isNotificationClosed';
import showNotification from './showNotification';
import CustomNotificationEvent from '@/client/events/CustomNotificationEvent';
import getGraphqlEndpoint from '@/shared/getGraphqlEndpoint';

type Props = Omit<WorkerContext, 'endpoint'> & {
  initialNotifications?: WorkerNotification[];
};

const NotificationWorkerHandler: FC<Props> = (props) => {
  useEffect(() => {
    const context: WorkerContext = { ...props, endpoint: getGraphqlEndpoint() };
    const worker = new Worker(new URL('./notificationWorker', import.meta.url));

    worker.onmessage = (event: MessageEvent) => {
      const notification = event.data as WorkerNotification;
      if (isNotificationClosed(notification)) {
        worker.postMessage({ type: 'sendNext' });
        return;
      }
      const onClose = () => {
        closeNotification(notification);
        worker.postMessage({ type: 'sendNext' });
      };
      showNotification({ notification, context, onClose });
    };
    worker.postMessage({ type: 'init', context });
    props.initialNotifications?.forEach((notification) =>
      worker.postMessage({ notification, type: 'add' }),
    );

    const customEvent = new CustomNotificationEvent((notification) =>
      worker.postMessage({ notification, type: 'add' }),
    );
    customEvent.subscribe();

    return () => {
      worker.terminate();
      customEvent.unsubscribe();
    };
  });

  return null;
};

export default NotificationWorkerHandler;
