import { useEffect } from 'react';
import useAcqOfferTags from './useAcqOfferTags';
import useSetMarketingTag from './useSetMarketingTag';
import isSsr from '@/shared/utils/isSsr';

const useMarketingTags = (
  isLoggedIn: boolean,
  site: Site,
  featureFlags: Map<string, boolean>,
) => {
  const [setFreespins_acq_offer] = useSetMarketingTag({
    tag: 'freespins_acq_offer',
    cookie: 'freespinsAcqOffer',
    featureFlags,
  });
  const [setAcq_offer] = useSetMarketingTag({
    tag: 'acq_offer',
    cookie: 'acq_offer',
    featureFlags,
  });
  const [setReactoonz_FS] = useSetMarketingTag({
    tag: 'reactoonz_FS',
    cookie: 'reactoonz_FS',
    featureFlags,
  });
  const [setRiseofMerlin_FS] = useSetMarketingTag({
    tag: 'RiseofMerlin_FS',
    cookie: 'RiseofMerlin_FS',
    featureFlags,
  });
  const [setMoonPrinces_FS] = useSetMarketingTag({
    tag: 'MoonPrinces_FS',
    cookie: 'MoonPrinces_FS',
    featureFlags,
  });
  useAcqOfferTags(isLoggedIn, site);

  useEffect(() => {
    const setMarketingTags = async () => {
      await setFreespins_acq_offer();
      await setAcq_offer();
      await setReactoonz_FS();
      await setRiseofMerlin_FS();
      await setMoonPrinces_FS();
    };
    if (!isLoggedIn || isSsr()) {
      return;
    }
    setMarketingTags();
  }, [
    isLoggedIn,
    setFreespins_acq_offer,
    setAcq_offer,
    setReactoonz_FS,
    setRiseofMerlin_FS,
    setMoonPrinces_FS,
  ]);
};

export default useMarketingTags;
