import { FormEventHandler, useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInputs from './PhoneInputs';
import Translation from '@/client/components/Translation';
import WarningBanner from '@/client/components/WarningBanner';
import Button from '@/external/components/Button';
import FormError from '@/shared/components/Form/FormError';

type Fields = {
  phone?: string;
};

type Props = {
  onSubmit: ({ phone }: Fields) => void;
  phoneNumber: string;
  site: Site;
  translations: Record<string, string>;
  domain: string;
  countryCode: string;
};

const PhoneNumberForm: FC<Props> = ({
  onSubmit,
  phoneNumber,
  site,
  translations,
  domain,
  countryCode,
}) => {
  const [phone, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    if (phone) {
      setIsValid(isValidPhoneNumber(phone));
    }
  }, [phone]);
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit({ phone });
  };
  const handleChange = (value: string) => {
    setPhoneNumber(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4">
        <WarningBanner
          bodyTranslateId="profile.sms_enabling_warning_for_mobile"
          translations={translations}
        />
        <p className="text-trunks">
          <Translation
            id="profile.enter_phone_number"
            translations={translations}
          />
        </p>
        <div>
          <PhoneInputs
            onChange={handleChange}
            value={phoneNumber}
            translations={translations}
            userCountryCode={countryCode}
            site={site}
          />
          <FormError
            message={isValid ? '' : 'errors.INVALID_PHONE'}
            data-testid="phone-form-error"
            domain={domain}
            translations={translations}
          />
        </div>
        <Button
          variant="primary"
          fullWidth
          size="md"
          disabled={!isValid || !phone}
          data-testid="send-code-button"
          type="submit"
        >
          <Translation id="profile.send_code" translations={translations} />
        </Button>
      </div>
    </form>
  );
};

export default PhoneNumberForm;
