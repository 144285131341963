'use client';

import Content from '../Content';
import handleMarkAsRead from '../graphql/handleMarkAsRead';
import useHandleNotificationClick from '../useHandleNotificationClick';
import Translation from '@/client/components/Translation';
import classNames from '@/external/classNames';
import Button from '@/external/components/Button';
import getCdnImg from '@/shared/cdn/getCdnImg';
import Img from '@/shared/components/Img';

const LoyaltyNotification: FC<NotificationViewProps> = ({
  notification,
  onClose,
  context,
}) => {
  const isButtonNeeded = notification.type === 'loyaltyMilestone';
  const isSportsbet = context.site === 'sportsbet';
  const isSbIo = context.brand === 'sbio';
  const isEmpire = context.site === 'empire';

  const handleClose = () => {
    handleMarkAsRead(notification.data);
    onClose();
  };

  const { handleNotificationClick } = useHandleNotificationClick({
    notification: notification.data,
    onClose: handleClose,
    context,
  });

  if (isSbIo) {
    return null;
  }

  return (
    <Content onClose={onClose}>
      <div
        className="grid cursor-pointer gap-2 pt-6"
        onClick={handleNotificationClick}
      >
        <div
          className={classNames(
            'relative m-auto flex h-12 w-12 flex-row items-center justify-center rounded-moon-i-sm bg-piccolo bg-top bg-no-repeat',
            'rounded-moon-i-sm border-solid before:absolute before:left-0 before:top-0 before:h-full before:w-full before:border-2 before:border-goten before:bg-transparent before:opacity-40',
            notification.type === 'loyaltyMilestone' && 'bg-frieza',
            isSportsbet && 'bg-bulma',
          )}
          style={{
            backgroundImage: `url('${getCdnImg(
              'loyalty/loyalty-icon-bg-pattern.svg',
            )}')`,
          }}
        >
          <Img height={37} width={37} src={notification.data.icon} alt={''} />
        </div>
        <h4 className="text-moon-18 font-medium text-popo">
          {notification.data.title}
        </h4>
        <p
          className={classNames(
            'text-moon-14 text-trunks',
            isEmpire && 'text-popo',
          )}
          dangerouslySetInnerHTML={{ __html: notification.data.body }}
        />
      </div>
      {isButtonNeeded && (
        <div className="flex flex-row items-center justify-center">
          <Button variant="primary" fullWidth onClick={handleNotificationClick}>
            <Translation
              id="notifications.loyalty.claim_now"
              translations={context.translations}
            />
          </Button>
        </div>
      )}
    </Content>
  );
};
export default LoyaltyNotification;
