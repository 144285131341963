import { useMemo, useState } from 'react';
import ToastContext from './ToastContext';
import Toast from '..';

const initialState: ToastProps = {
  message: '',
  variant: 'success',
  position: 'bottom',
  timeout: 5000,
};

const ToastContextProvider: FC = ({ children }) => {
  const [toastParams, setToastParams] = useState(initialState);

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      setToastParams(initialState);
    }
  };

  const values = useMemo(
    () => ({
      toastParams,
      setToastParams,
    }),
    [toastParams],
  );

  return (
    <ToastContext.Provider value={values}>
      <Toast
        message={toastParams.message}
        isOpen={!!toastParams.message}
        onOpenChange={handleOpenChange}
        variant={toastParams.variant}
      />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
