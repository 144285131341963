import Amount from '../shared/Amount';
import Box from '../shared/Box';
import Label from '../shared/Label';

type Props = {
  boost: BoostTimerFragment;
  translations: Record<string, string>;
  language: LanguageCode;
};

const BoostAmount: FC<Props> = ({ boost, translations, language }) => (
  <Box>
    <Label translateId="casino.boosts.boost" translations={translations} />
    <Amount
      amount={boost.boostAmount}
      currency={boost.currency as CashierCurrency}
      language={language}
    />
  </Box>
);

export default BoostAmount;
