import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import type { CountryCode } from 'libphonenumber-js';
import getCallingCodes from './helpers/getCallingCodes';
import usePhoneDetails from './usePhoneDetails';

type Props = {
  value: string;
  onChange: (phoneString: string) => void;
  error?: string;
  site: Site;
  translations: Record<string, string>;
  userCountryCode: string;
};

const usePhoneFields = ({
  onChange,
  value,
  site,
  translations,
  userCountryCode,
}: Props) => {
  const {
    countryCode,
    countryNumber,
    phoneNumber,
    handleNumberChange,
    setCountryCode,
  } = usePhoneDetails({ value, userCountryCode });

  const options = useMemo(() => getCallingCodes(site), [site]);

  const getCountryCodeOption = useCallback(
    (countryPhoneCode: string) =>
      options.find((option) => option.value === countryPhoneCode),
    [options],
  );
  const phoneCountryOption = getCountryCodeOption(countryCode);

  const [countryCodeOption, setCountryCodeOption] =
    useState(phoneCountryOption);

  useEffect(() => {
    setCountryCodeOption(getCountryCodeOption(countryCode));
  }, [countryCode, setCountryCodeOption, getCountryCodeOption]);

  useEffect(() => {
    if (phoneNumber === '') {
      onChange('');
    }
    if (phoneNumber) {
      const phoneString =
        countryCode && phoneNumber.length
          ? `+${countryNumber}${phoneNumber}`
          : phoneNumber.toString();
      onChange(phoneString);
    }
  }, [countryCode, countryNumber, onChange, phoneNumber]);

  const handleCodeChange = (option: ChangeEvent<HTMLSelectElement>) => {
    const code = option.currentTarget.value;
    setCountryCode(code as CountryCode);
  };

  const selectPlaceholder = translations['profile.country_code'];
  const inputPlaceholder = translations['profile.phone'];

  const selectProps = {
    type: 'select',
    options,
    placeholderSlot: selectPlaceholder,
    value: countryCodeOption?.value,
    isSearchable: true,
    ['data-testid']: 'country-code-select',
  };

  const inputProps = {
    type: 'tel',
    placeholder: inputPlaceholder,
    onChange: handleNumberChange,
    value: phoneNumber.toString(),
    ['data-testid']: 'phone-number-input',
  };

  return {
    selectProps,
    inputProps,
    handleCodeChange,
  };
};

export default usePhoneFields;
