import { useCallback } from 'react';

import { getRewardsNftDropClaimRewardMutation } from '../graphql/RewardsNftDropClaimRewardMutation.generated';
import useToastContext from '@/client/components/Toast/Context/useToastContext';
import useClientTranslations from '@/client/hooks/useClientTranslations';

const useClaimNftReward = () => {
  const { showToast } = useToastContext();
  const translations = useClientTranslations([
    'reward.nft_claim_error',
    'reward.nft_claim_success',
  ]) as Record<string, string>;

  const handleClaimNftReward = useCallback(
    async (bonusOfferId: string) => {
      const { data } = await getRewardsNftDropClaimRewardMutation({
        input: {
          bonusOfferId,
        },
      });
      const { errors } = data?.rewardsNftDropClaimReward ?? {};
      if (errors) {
        showToast({
          message: translations['reward.nft_claim_error'],
          variant: 'error',
          timeout: 6000,
        });
        return;
      }
      showToast({
        message: translations['reward.nft_claim_success'],
        variant: 'success',
        timeout: 6000,
      });
    },
    [showToast, translations],
  );

  return { handleClaimNftReward };
};

export default useClaimNftReward;
