// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
import { TagsFragmentDoc } from '../../../../../server/graphql/getTags/TagsQuery.generated';
export type ToggleTagMutationVariables = Types.Exact<{
  tag: Types.Scalars['String']['input'];
  value?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ToggleTagMutation = { __typename?: 'Mutation', featuresToggleTag?: { __typename?: 'FeaturesToggleTagPayload', isTagEnabled?: boolean, features?: { __typename: 'Features', id: string, marketingEnabled?: boolean, marketingEnabledSMS?: boolean, marketingEnabledCall?: boolean, marketingEnabledDirectMail?: boolean, videoNotificationsDisabled?: boolean, uBTC?: boolean, mBTC?: boolean, VIP?: boolean, Insider?: boolean, isAmsUser?: boolean, isVip?: boolean, isConfirmedOppositeBetting?: boolean, isLoyaltyDisabled?: boolean }, errors?: Array<{ __typename?: 'FeaturesError', code?: number, message?: string }> } };


export const ToggleTagMutationDocument = gql`
    mutation ToggleTagMutation($tag: String!, $value: Boolean) {
  featuresToggleTag(input: {tag: $tag, on: $value}) {
    features {
      ...TagsFragment
    }
    isTagEnabled
    errors {
      code
      message
    }
  }
}
    ${TagsFragmentDoc}`;

export const getToggleTagMutation = async (variables: ToggleTagMutationVariables) => {
  return fetchGraphql<ToggleTagMutation, ToggleTagMutationVariables>(ToggleTagMutationDocument, "ToggleTagMutation", { variables });
}