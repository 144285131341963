import { UserManagement } from 'app/@types/schema';
import { GeolocationIpCountries } from '@/schema';

const trimLanguageCode = (code: string) => code.substr(0, 2);

type Args = {
  user: UserManagement['currentUser'];
  profile: UserManagement['currentProfile'];
  language?: string;
  withOpenFrame?: boolean;
  siteConfig: SiteConfig;
  countryGeoInfo?: GeolocationIpCountries;
  onChatFrameClosed?: () => void;
};

const getCommsConfig = ({
  user,
  profile,
  countryGeoInfo,
  withOpenFrame,
  onChatFrameClosed,
  siteConfig,
  language = '',
}: Args) => {
  const externalId = profile?._id;
  const { cityName, countryName } = countryGeoInfo || {};
  const location = `${cityName}, ${countryName}`;
  return {
    ...siteConfig.commsConfig,
    location,
    withOpenFrame,
    onChatFrameClosed,
    sessionUrl: global.FS?.getCurrentSessionURL?.(),
    language: trimLanguageCode(language),
    customer: {
      externalId,
      name: user?.username,
      email: user?.email,
    },
  };
};

export default getCommsConfig;
