// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type ClaimBoostMutationVariables = Types.Exact<{
  input: Types.CasinoBoostsPublicClaimBoostRewardMutationInput;
}>;


export type ClaimBoostMutation = { __typename?: 'Mutation', casinoBoostsPublicClaimBoostRewardMutation?: { __typename?: 'CasinoBoostsPublicClaimBoostRewardMutationPayload', success?: boolean, boostTimer?: { __typename?: 'CasinoBoostsPublicBoostTimer', id: string, claimed?: boolean, rewardClaimDate?: string }, errors?: Array<{ __typename?: 'CasinoBoostsPublicError', code?: number, message?: string }> } };


export const ClaimBoostMutationDocument = gql`
    mutation ClaimBoostMutation($input: CasinoBoostsPublicClaimBoostRewardMutationInput!) {
  casinoBoostsPublicClaimBoostRewardMutation(input: $input) {
    boostTimer {
      id
      claimed
      rewardClaimDate
    }
    success
    errors {
      code
      message
    }
  }
}
    `;

export const getClaimBoostMutation = async (variables: ClaimBoostMutationVariables) => {
  return fetchGraphql<ClaimBoostMutation, ClaimBoostMutationVariables>(ClaimBoostMutationDocument, "ClaimBoostMutation", { variables });
}