// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../../schema';

import fetchGraphql from '@/server/helpers/fetchQuery';
import gql from '@/shared/utils/gql';
export type MyBonusOffersNftLinkQueryVariables = Types.Exact<{
  currency?: Types.InputMaybe<Types.Scalars['String']['input']>;
  code?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type MyBonusOffersNftLinkQuery = { __typename?: 'Query', rewards?: { __typename?: 'Rewards', id: string, myBonusOffers?: { __typename?: 'RewardsBonusOfferConnection', edges?: Array<{ __typename?: 'RewardsBonusOfferEdge', node?: { __typename?: 'RewardsBonusOffer', id: string, name?: string, isDepositBonus?: boolean, isNftLink?: boolean, endTime?: string, minDeposit?: number, termsExternal?: string, currency?: string, rewards?: Array<{ __typename?: 'RewardsBonusOfferReward', name?: string, wagerReq?: number, value?: number }> } }> } } };

export type BonusOfferFragment = { __typename?: 'RewardsBonusOffer', id: string, name?: string, isDepositBonus?: boolean, isNftLink?: boolean, endTime?: string, minDeposit?: number, termsExternal?: string, currency?: string, rewards?: Array<{ __typename?: 'RewardsBonusOfferReward', name?: string, wagerReq?: number, value?: number }> };

export const BonusOfferFragmentDoc = gql`
    fragment BonusOfferFragment on RewardsBonusOffer {
  id
  name
  isDepositBonus
  isNftLink
  endTime
  minDeposit
  termsExternal
  currency
  rewards {
    name
    wagerReq
    value
  }
}
    `;
export const MyBonusOffersNftLinkQueryDocument = gql`
    query MyBonusOffersNftLinkQuery($currency: String, $code: String) {
  rewards {
    id
    myBonusOffers(first: 100, currency: $currency, code: $code) {
      edges {
        node {
          ...BonusOfferFragment
        }
      }
    }
  }
}
    ${BonusOfferFragmentDoc}`;

export const getMyBonusOffersNftLinkQuery = async (variables: MyBonusOffersNftLinkQueryVariables) => {
  return fetchGraphql<MyBonusOffersNftLinkQuery, MyBonusOffersNftLinkQueryVariables>(MyBonusOffersNftLinkQueryDocument, "MyBonusOffersNftLinkQuery", { variables });
}