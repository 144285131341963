import useResendCode from './hooks/useResendCode';
import ResendCodeButton from './ResendCodeButton';
import Dialog from '../../Dialog';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';
import DevicesSmartphone from '@/external/icons/DevicesSmartphone';
import SecurityQrCode from '@/external/icons/SecurityQrCode';

type Props = {
  onClose: () => void;
  onUseAppClick: () => void;
  phoneNumber: string;
  onCodeResendSuccess: () => void;
  onClickBack: () => void;
  onChangePhoneClick: () => void;
  translations: Record<string, string>;
  isMobile: boolean;
};

const MoreOptionsDialog: FC<Props> = ({
  onClose,
  onUseAppClick,
  phoneNumber,
  onCodeResendSuccess,
  onClickBack,
  onChangePhoneClick,
  translations,
  isMobile,
}) => {
  const { handleResendCodeClick } = useResendCode(
    phoneNumber,
    onCodeResendSuccess,
    translations,
  );
  return (
    <Dialog
      onClose={onClose}
      title="profile.more_options"
      onClickBack={onClickBack}
      translations={translations}
      isMobile={isMobile}
    >
      <p className="text-trunks">
        <Translation id="profile.choose_method" translations={translations} />
      </p>
      <div className="grid gap-2">
        <ResendCodeButton
          onResendCodeClick={handleResendCodeClick}
          translations={translations}
        />
        <Button
          iconLeft={<DevicesSmartphone className="text-moon-32" />}
          variant="secondary"
          fullWidth
          size="lg"
          onClick={onChangePhoneClick}
          data-testid="change-phone-button"
        >
          <Translation
            id="profile.change_phone_number"
            translations={translations}
          />
        </Button>
        <Button
          iconLeft={<SecurityQrCode className="text-moon-32" />}
          variant="secondary"
          fullWidth
          size="lg"
          onClick={onUseAppClick}
          data-testid="use-app-button"
        >
          <Translation
            id="profile.use_app_authentication"
            translations={translations}
          />
        </Button>
      </div>
    </Dialog>
  );
};

export default MoreOptionsDialog;
