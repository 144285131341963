import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import formatPhoneCallingCode from './helpers/formatPhoneCallingCode';
import getCountryCallingCode from './helpers/getCountryCallingCode';
import parsePhoneNumber from './helpers/parsePhoneNumber';
import { ProfileFragment } from '@/server/graphql/getUser/UserQuery.generated';

type Props = {
  value?: string;
  profile?: ProfileFragment;
  userCountryCode: string;
};

const usePhoneDetails = ({ value = '', profile, userCountryCode }: Props) => {
  const phoneDetails = parsePhoneNumber(
    value || !profile?.phone ? value : profile.phone,
  );
  const phoneCountry = phoneDetails?.country ?? userCountryCode;

  const registeredCallingCode = getCountryCallingCode(phoneCountry);

  const [countryCode, setCountryCode] = useState(phoneCountry);
  const [countryNumber, setCountryNumber] = useState(registeredCallingCode);
  const [phoneNumber, setPhoneNumber] = useState(phoneDetails?.number ?? '');

  useEffect(() => {
    const parsedPhone = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumber.startsWith('+')) {
      const country = parsedPhone?.country;
      if (country) {
        setCountryCode(country);
        const { phone, code } = formatPhoneCallingCode({
          country,
          callingCode: parsedPhone.countryCallingCode,
          phone: parsedPhone.number.toString(),
        });
        setPhoneNumber(phone);
        setCountryNumber(code);
      }
    }
  }, [phoneNumber, countryCode, countryNumber]);

  const handleNumberChange = (event: FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const nationalNumber = event.currentTarget.value;
    setPhoneNumber(nationalNumber);
  };

  const handleCodeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const code = event.currentTarget.value;
    setCountryCode(code);
  };

  useEffect(() => {
    setCountryNumber(getCountryCallingCode(countryCode));
  }, [countryCode, setCountryNumber]);

  return {
    countryCode,
    countryNumber,
    phoneNumber,
    handleCodeChange,
    handleNumberChange,
    setCountryCode,
  };
};

export default usePhoneDetails;
