import { fromInteger } from '@heathmont/money';
import mapEventToGtm from './mapEventToGtm';
import { PendingEventFragment } from '../../analyticsWorker/graphql/mapUserIdToGID/MapUserIdToGaIdMutation.generated';
import sendToGtm from '@/client/helpers/sendToGtm';

type Args = {
  pendingEvents: PendingEventFragment[];
  confirmedDepositsCount: number;
};

const sendEventsToAnalytics = (args: Args) => {
  const { pendingEvents, confirmedDepositsCount } = args;
  pendingEvents.forEach((event) => {
    const mappedEvent = mapEventToGtm({ event, confirmedDepositsCount });
    if (mappedEvent.event) {
      const { currency, amount, exchangeRate, eurAmount } = event;
      const analyticEvent = {
        cryptoName: currency,
        cryptoValue:
          amount && currency
            ? fromInteger(amount, currency).toString()
            : amount,
        eurAmount,
        cryptoConvRate: exchangeRate,
        cryptoProvider: undefined,
        ...mappedEvent,
      };
      sendToGtm(analyticEvent);
      global.imamu(analyticEvent.event, analyticEvent);
    }
  });
};

export default sendEventsToAnalytics;
