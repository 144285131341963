'use client';
import Cookies from 'js-cookie';
import LocalStorage from '@/client/utils/storage/LocalStorage';
import SessionStorage from '@/client/utils/storage/SessionStorage';

const isNotificationClosed = (notification: WorkerNotification) => {
  const { id } = notification.data;
  if (!id) {
    return false;
  }
  const { dismissStrategy = { storage: 'session' } } = notification;
  if (dismissStrategy.storage === 'cookie') {
    return !!Cookies.get(id);
  }
  if (dismissStrategy.storage === 'local') {
    return !!LocalStorage.getItem(id);
  }
  return !!SessionStorage.getItem(id);
};

export default isNotificationClosed;
