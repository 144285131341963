import Congratulation from './Congratulation';
import Results from './Results';

type Props = { boost: BoostTimerFragment; context: WorkerContext };

const Body: FC<Props> = ({ boost, context }) => (
  <div className="grid gap-8">
    <Congratulation context={context} />
    <Results
      boost={boost}
      translations={context.translations}
      language={context.language as LanguageCode}
    />
  </div>
);

export default Body;
