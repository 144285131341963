import { getForfeitFSRewardMutation } from '../graphql/ForfeitFSRewardMutation.generated';

type Args = {
  id: string;
  onClose: () => void;
};

const useForfeitReward = ({ id, onClose }: Args) => {
  const handleForfeit = async () => {
    await getForfeitFSRewardMutation({
      id,
    });
    onClose();
  };

  return { handleForfeit };
};

export default useForfeitReward;
