import Translation from '@/client/components/Translation';

type Props = {
  context: WorkerContext;
};

const Congratulation = ({ context }: Props) => (
  <h5 className="pt-6 font-headline text-moon-24 text-bulma">
    <Translation
      id="general.congratulations"
      translations={context.translations}
    />
  </h5>
);

export default Congratulation;
