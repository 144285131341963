'use client';
import { useEffect } from 'react';
import transformExperiments from '../helpers/transformExperiments';
import updateCustomAbTest from '../helpers/updateCustomAbTest';
import { getStoreAbTestDataMutation } from '@/server/components/StoreAbTestData/graphql/StoreAbTestDataMutation.generated';

type Props = {
  token: string;
};

const ExecuteStoreAbTestData: FC<Props> = ({ token }) => {
  useExecuteStoreAbTestData(token);
  return null;
};

const storeAbTest = async (token: string) => {
  try {
    const rawExperiments = global.convert?.currentData?.experiments;
    if (!rawExperiments) {
      return;
    }
    const experimentsString = JSON.stringify(
      transformExperiments(rawExperiments),
    );
    if (!experimentsString) {
      return;
    }
    const hash = token.substring(token.length - 5) + experimentsString;
    const lastAbTestingHash = localStorage.getItem('lastAbTestingHash');
    if (lastAbTestingHash === hash) {
      return;
    }
    const result = await getStoreAbTestDataMutation({
      experiments: experimentsString,
    });
    if (result.data?.testCasesInsertUniqueTestCase?.success) {
      localStorage.setItem('lastAbTestingHash', hash);
    }
  } catch (error) {
    console.error('storeAbTest error', error);
  }
};

const useExecuteStoreAbTestData = (token: string) => {
  useEffect(() => {
    if (localStorage === undefined) {
      return;
    }
    storeAbTest(token);
    updateCustomAbTest();
  }, [token]);
};

export default ExecuteStoreAbTestData;
