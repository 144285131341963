import { ChangeEventHandler, useMemo, useState } from 'react';
import { getEnable2FAMutation } from './graphql/Enable2FAMutation.generated';
import generateOtpKey from './helpers/generateOtpKey';
import useToastContext from '@/client/components/Toast/Context/useToastContext';

const useEnable2FA = (
  translations: Record<string, string>,
  onSuccess?: () => void,
) => {
  const { showToast } = useToastContext();
  const otpKey = useMemo(() => generateOtpKey(), []);
  const [isError, setIsError] = useState(false);
  const [otp, setOtp] = useState('');
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setIsError(false);
    setOtp(event.target.value);
  };
  return {
    isError,
    otpKey,
    handleSubmit: async () => {
      if (!otp) {
        setIsError(true);
        return;
      }
      setIsError(false);
      const { data } = await getEnable2FAMutation({
        otp,
        otpKey,
      });
      const { errors: mutationErrors } = data?.userManagementEnable2FA || {};
      if (mutationErrors?.length) {
        setIsError(true);
        return;
      }
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      const translationKey = 'modals.enable_2fa_success.header';
      showToast({
        message: translations.hasOwnProperty(translationKey)
          ? translations[translationKey]
          : translationKey,
        timeout: 6000,
        variant: 'success',
        position: 'bottom',
      });
    },
    handleChange,
    inputValue: otp,
  };
};
export default useEnable2FA;
