type Params = {
  args: {
    gwId: string;
    lang?: string;
    humanUid?: string;
  };
  onComplete?: () => void;
};

type ProtoCommandsType = {
  show: () => void;
  hide: () => void;
  init: (params: Params) => void;
  open: () => void;
  close: () => void;
};

const ProtoCommands: ProtoCommandsType = {
  show: () => global.proto?.show(),
  hide: () => global.proto?.hide(),
  init: ({ args, onComplete }) => {
    global.proto?.init(args, onComplete);
  },
  open: () => {
    try {
      global.proto?.open();
    } catch (error) {}
  },
  close: () => global.proto?.close(),
};

export default ProtoCommands;
