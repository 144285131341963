import { convertUnit, fromInteger } from '@heathmont/money';

type Props = {
  amount: number;
  currency: CashierCurrency;
  coinInSatoshi?: boolean;
};

const useParsedDisplayAmount = ({ amount, currency, coinInSatoshi }: Props) => {
  if (!coinInSatoshi) {
    if (currency === 'BTC') {
      return convertUnit(amount, currency, 'BTC', 'mBTC');
    }
    return amount;
  }
  if (currency === 'BTC') {
    return fromInteger(amount, currency, 'mBTC');
  }
  return fromInteger(amount, currency);
};

export default useParsedDisplayAmount;
