// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type MarkAsReadMutationVariables = Types.Exact<{
  notificationId: Types.Scalars['ID']['input'];
}>;


export type MarkAsReadMutation = { __typename?: 'Mutation', casinoNotificationMarkAsRead?: { __typename?: 'CasinoNotificationMarkAsReadMutationPayload', updatedNotification?: { __typename?: 'CasinoNotificationNormalNotification', id: string, isRead?: boolean }, errors?: Array<{ __typename?: 'CasinoNotificationError', message?: string, params?: Array<{ __typename?: 'CasinoNotificationErrorParam', name?: string, value?: string }> }>, casinoNotification?: { __typename?: 'CasinoNotification', id: string, currentUnreadNotificationCount?: number } } };


export const MarkAsReadMutationDocument = gql`
    mutation MarkAsReadMutation($notificationId: ID!) {
  casinoNotificationMarkAsRead(input: {notificationId: $notificationId}) {
    updatedNotification {
      id
      isRead
    }
    errors {
      message
      params {
        name
        value
      }
    }
    casinoNotification {
      id
      currentUnreadNotificationCount
    }
  }
}
    `;

export const getMarkAsReadMutation = async (variables: MarkAsReadMutationVariables) => {
  return fetchGraphql<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadMutationDocument, "MarkAsReadMutation", { variables });
}