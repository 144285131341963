import Cookies from 'js-cookie';

const delay = 30 * 1000;
const cookieName = 'enable-sms-resending-time-on';

const useResendingEnableTimeCookie = () => {
  const cookieValue = Number(Cookies.get(cookieName));
  const resendingEnableTimeCookie = isNaN(cookieValue)
    ? new Date().getTime() + delay
    : cookieValue;
  const setResendingEnableTime = (value: number) =>
    Cookies.set(cookieName, value.toString());

  const setResendingEnableTimeCookie = () => {
    if (resendingEnableTimeCookie <= new Date().getTime() + delay) {
      setResendingEnableTime(new Date().getTime() + delay);
    }
  };
  return {
    resendingEnableTimeCookie,
    setResendingEnableTimeCookie,
  };
};

export default useResendingEnableTimeCookie;
