import { PendingEventFragment } from '../../analyticsWorker/graphql/mapUserIdToGID/MapUserIdToGaIdMutation.generated';

type Args = {
  event: PendingEventFragment;
  confirmedDepositsCount: number;
};

const mapEventToGtm = ({ event, confirmedDepositsCount }: Args) => {
  const { type, itemId, currency, amount = '0' } = event;
  if (type === 'withdrawFail' && +amount > 0) {
    return {
      event: 'withdrawFail',
      failReason: undefined,
    };
  }
  if (type === 'withdrawSuccess' && +amount > 0) {
    return {
      event: 'withdrawComplete',
      amount,
      currency,
    };
  }
  if (type === 'depositStart') {
    return { event: 'depositInitiate' };
  }
  if (type === 'depositSuccess') {
    return {
      event:
        confirmedDepositsCount > 1 ? 'depositComplete' : 'depositCompleteFirst',
      depositId: itemId,
      amount,
      currency,
    };
  }
  return { depositId: undefined, failReason: undefined, event: undefined };
};

export default mapEventToGtm;
