import getCdnMoonIcon from '@/shared/cdn/getCdnMoonIcon';
import Img from '@/shared/components/Img';

const GenericTrophy = () => (
  <Img
    src={getCdnMoonIcon('icons/GenericTrophy.png')}
    alt=""
    width={24}
    height={24}
    dpr={2}
  />
);
export default GenericTrophy;
