// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type SportsbetUpdateCurrencyMutationVariables = Types.Exact<{
  input: Types.SportsbetPreferencesUpdateCurrencyInput;
}>;


export type SportsbetUpdateCurrencyMutation = { __typename?: 'Mutation', sportsbetPreferencesUpdateCurrency?: { __typename?: 'SportsbetPreferencesUpdateCurrencyPayload', userPreferences?: { __typename?: 'SportsbetPreferencesUserPreference', id: string, currency: Types.Currency }, errors?: Array<{ __typename?: 'SportsbetPreferencesError', message?: string, code?: number }> } };


export const SportsbetUpdateCurrencyMutationDocument = gql`
    mutation SportsbetUpdateCurrencyMutation($input: SportsbetPreferencesUpdateCurrencyInput!) {
  sportsbetPreferencesUpdateCurrency(input: $input) {
    userPreferences {
      id
      currency
    }
    errors {
      message
      code
    }
  }
}
    `;

export const getSportsbetUpdateCurrencyMutation = async (variables: SportsbetUpdateCurrencyMutationVariables) => {
  return fetchGraphql<SportsbetUpdateCurrencyMutation, SportsbetUpdateCurrencyMutationVariables>(SportsbetUpdateCurrencyMutationDocument, "SportsbetUpdateCurrencyMutation", { variables });
}