// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type UpdateMyDefaultCurrencyMutationVariables = Types.Exact<{
  currency: Types.Scalars['String']['input'];
}>;


export type UpdateMyDefaultCurrencyMutation = { __typename?: 'Mutation', featuresToggleCurrency?: { __typename?: 'FeaturesToggleCurrencyPayload', isTagEnabled?: boolean, features?: { __typename?: 'Features', id: string, walletCurrency?: string }, errors?: Array<{ __typename?: 'FeaturesError', message?: string, code?: number, params?: Array<{ __typename?: 'FeaturesErrorParam', name?: string, value?: string }> }> } };


export const UpdateMyDefaultCurrencyMutationDocument = gql`
    mutation UpdateMyDefaultCurrencyMutation($currency: String!) {
  featuresToggleCurrency(input: {currency: $currency}) {
    isTagEnabled
    features {
      id
      walletCurrency: myGroupTag(name: "DepositCurrency")
    }
    errors {
      message
      code
      params {
        name
        value
      }
    }
  }
}
    `;

export const getUpdateMyDefaultCurrencyMutation = async (variables: UpdateMyDefaultCurrencyMutationVariables) => {
  return fetchGraphql<UpdateMyDefaultCurrencyMutation, UpdateMyDefaultCurrencyMutationVariables>(UpdateMyDefaultCurrencyMutationDocument, "UpdateMyDefaultCurrencyMutation", { variables });
}