'use client';
import useCashier from './useCashier';
import MicroFrontend from '@/client/components/MicroFrontend';
import classNames from '@/external/classNames';
import { CashierCurrencyType } from '@/schema';

type Props = {
  isMobile: boolean;
  site: Site;
  type: CashierCurrencyType;
  name: CashierCurrency;
  isSbio: boolean;
  isCashierEnabled: boolean;
  currentTheme?: ColorScheme;
  cashierUrl: string;
  isProtoEnabled: boolean;
};

const CashierWrap: FC<Props> = ({
  isMobile,
  site,
  type,
  name,
  isSbio,
  isCashierEnabled,
  currentTheme,
  cashierUrl,
  isProtoEnabled,
}) => {
  const { isOpen, elementRef, handleClose } = useCashier({
    site,
    isMobile,
    type,
    name,
    isCashierEnabled,
    isSbio,
    currentTheme,
    isProtoEnabled,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-10000 bg-popo/70">
      <main
        className={classNames(
          'fixed z-10000 h-full w-full overflow-y-scroll bg-gohan sm:absolute sm:max-w-md',
          !isMobile && 'ltr:right-0 rtl:left-0',
          site === 'bitcasino' &&
            '[&_.btn-primary]:text-goku [&_.btn-tertiary]:text-bulma',
        )}
        ref={elementRef}
      >
        <MicroFrontend
          host={cashierUrl}
          onClose={handleClose}
          currentTheme={currentTheme}
          name="Cashier"
        />
      </main>
    </div>
  );
};
export default CashierWrap;
