import ActionButton from './ActionButton';
import getKycNotificationContent from './helpers/getKycNotificationContent';
import Translation from '@/client/components/Translation';
import classNames from '@/external/classNames';
import getThemeClassName from '@/shared/utils/getThemeClassName';

const KycNotification: FC<NotificationViewProps> = ({
  notification,
  onClose,
  context,
}) => {
  const { name = '' } = notification.data || {};
  const { icon, text, action } = getKycNotificationContent(name);
  const isDeclined = name === 'kyc_failed';
  const isSuccess = name === 'kyc_processing' || name === 'kyc_accepted';
  const declineReasons =
    notification.data?.verificationProcess?.declineReasons || [];

  return (
    <div
      data-testid={`kyc-notification-${name}`}
      className={classNames(
        'grid cursor-pointer gap-4 rounded-moon-i-sm border border-solid border-beerus bg-gohan p-4 shadow-moon-lg ltr:text-left rtl:text-right',
        getThemeClassName(context.site, 'light'),
      )}
    >
      <div className="grid grid-flow-col grid-cols-[1fr_auto] items-center gap-3">
        <div
          className={classNames(
            'grid grid-flow-col grid-cols-[auto_1fr] items-center gap-3',
            isDeclined && 'items-start',
          )}
        >
          <div
            className={classNames(
              'flex h-10 w-10 items-center justify-center rounded-moon-i-sm bg-krillin-10',
              isDeclined && 'bg-chichi-10',
              isSuccess && 'bg-roshi-10',
            )}
          >
            {icon}
          </div>
          <div className="grid">
            <p
              className={classNames(
                'text-moon-14 text-bulma',
                isDeclined && 'text-chichi',
              )}
            >
              <Translation
                translations={context.translations}
                id={`notifications.kyc.${text}`}
              />
            </p>
            {isDeclined && (
              <ul className="pl-5">
                {declineReasons.map((declineReason: string) => (
                  <li key={declineReason}>
                    <p className="text-moon-12">
                      <Translation
                        translations={context.translations}
                        id={`kyc.decline_reasons.${declineReason}`}
                      />
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <ActionButton
          notification={notification.data}
          action={action}
          onClose={onClose}
          translations={context.translations}
          context={context}
        />
      </div>
    </div>
  );
};

export default KycNotification;
