import { useState } from 'react';
import { getStartEnablingSMS2FAMutation } from './graphql/StartEnablingSMS2FAMutation.generated';
import useResendingEnableTimeCookie from '../MoreOptionsDialog/ResendCodeButton/useResendingEnableTimeCookie';
import useToastContext from '@/client/components/Toast/Context/useToastContext';

type Fields = {
  phone?: string;
};

const useSmsAuthEnabling = (
  onClose: () => void,
  language: string,
  translations: Record<string, string>,
) => {
  const { setResendingEnableTimeCookie } = useResendingEnableTimeCookie();
  const { showToast } = useToastContext();
  const [step, setStep] = useState('phone_number');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleNumberSubmit = async ({ phone = '' }: Fields) => {
    const result = await getStartEnablingSMS2FAMutation({
      phone,
      language,
    });
    const { errors: mutationErrors } =
      result.data?.userManagementStartEnablingSMS2FA || {};
    if (mutationErrors?.length) {
      console.error(mutationErrors);
      return;
    }
    setResendingEnableTimeCookie();
    setPhoneNumber(phone);
    setStep('code');
  };
  const handleCodeSubmit = () => {
    showToast({ message: translations['profile.sms_auth_enabled'] });
    onClose();
  };

  const handleEnterPhoneClick = () => setStep('phone_number');
  const handleEnterCodeClick = () => setStep('code');
  const handleMoreOptionsClick = () => setStep('more_options');

  return {
    step,
    handleNumberSubmit,
    phoneNumber,
    handleCodeSubmit,
    handleEnterPhoneClick,
    handleEnterCodeClick,
    handleMoreOptionsClick,
  };
};

export default useSmsAuthEnabling;
