/* eslint-disable immutable/no-let */
const generateOtpKey = () => base32Encode(generateSecretASCII());

const generateSecretASCII = () => {
  const bytes = global.crypto.getRandomValues(new Uint8Array(15));
  const set = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  let output = '';
  for (let i = 0, l = bytes.byteLength; i < l; i = i + 1) {
    output += set[Math.floor((bytes[i] / 255.0) * (set.length - 1))];
  }
  return output;
};

const base32Encode = (text: string) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
  const length = text.length;
  let bits = 0;
  let value = 0;
  let output = '';
  for (let i = 0; i < length; i = i + 1) {
    value = (value << 8) | text.charCodeAt(i);
    bits += 8;
    while (bits >= 5) {
      output += alphabet[(value >>> (bits - 5)) & 31];
      bits -= 5;
    }
  }
  if (bits > 0) {
    output += alphabet[(value << (5 - bits)) & 31];
  }
  return output;
};

export default generateOtpKey;
