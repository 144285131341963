// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
import { ErrorsFragmentDoc } from '../../../../../../../../shared/graphql/UpdateUserSettingsMutation.generated';
export type SetInGameCurrencyMutationVariables = Types.Exact<{
  preferredInGameCurrency?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SetInGameCurrencyMutation = { __typename?: 'Mutation', userManagementUpdateUserSettings?: { __typename?: 'UserManagementUpdateUserSettingsMutationPayload', userManagement?: { __typename?: 'UserManagement', id: string, profile?: { __typename?: 'UserManagementProfile', id: string, settings?: { __typename?: 'UserManagementSettings', preferredInGameCurrency?: string } } }, errors?: Array<{ __typename?: 'UserManagementError', message?: string, params?: Array<{ __typename?: 'UserManagementErrorParam', name?: string, value?: string }> }> } };


export const SetInGameCurrencyMutationDocument = gql`
    mutation SetInGameCurrencyMutation($preferredInGameCurrency: String) {
  userManagementUpdateUserSettings(
    input: {name: preferredInGameCurrency, value: $preferredInGameCurrency}
  ) {
    userManagement {
      id
      profile: currentProfile {
        id
        settings {
          preferredInGameCurrency: setting(name: preferredInGameCurrency)
        }
      }
    }
    errors {
      ...ErrorsFragment
    }
  }
}
    ${ErrorsFragmentDoc}`;

export const getSetInGameCurrencyMutation = async (variables: SetInGameCurrencyMutationVariables) => {
  return fetchGraphql<SetInGameCurrencyMutation, SetInGameCurrencyMutationVariables>(SetInGameCurrencyMutationDocument, "SetInGameCurrencyMutation", { variables });
}