/* eslint-disable immutable/no-mutation */
import React, { useEffect, useState } from 'react';
import CashierLoadingSkeleton from './CashierLoadingSkeleton';

type Props = {
  name: string;
  host: string;
  onClose: () => void;
  currentTheme: ColorScheme | undefined;
};

const MicroFrontend: FC<Props> = ({ name, host, onClose, currentTheme }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const scriptId = `mf-cashier-script-${name}`;
    const renderMicroFrontend = () => {
      global.renderCashier?.(`${name}-container`);
      setIsLoading(false);
    };

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
      return;
    }

    fetch(`${host}/dist/manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        const script = document.createElement('script');
        const stylesheet = document.createElement('link');

        Promise.all([
          new Promise<void>((resolve) => {
            script.addEventListener('load', () => {
              resolve();
            });
          }),
          new Promise<void>((resolve) => {
            stylesheet.addEventListener('load', () => {
              resolve();
            });
          }),
        ]).then(() => {
          renderMicroFrontend();
        });

        script.id = scriptId;
        script.crossOrigin = '';
        script.type = 'module';
        script.src = `${host}/dist/${manifest['index.html']['file']}`;
        document.head.appendChild(script);

        stylesheet.rel = 'stylesheet';
        stylesheet.href = `${host}/dist/${manifest['style.css']['file']}`;
        document.head.appendChild(stylesheet);
      });

    () => {
      global.unmountCashier?.();
    };
  }, [host, name]);

  return (
    <>
      {isLoading && (
        <CashierLoadingSkeleton onClose={onClose} currentTheme={currentTheme} />
      )}
      <main
        id={`${name}-container`}
        style={{
          height: '100%',
          display: isLoading ? 'none' : 'block',
          position: 'relative',
        }}
      />
    </>
  );
};

export default MicroFrontend;
