'use client';
import { useCallback, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import getIsRtl from './utils/getIsRtl';
import getCurrentClientLocation from '@/actions/getCurrentClientLocation';
import { GeolocationIpCountries, UserManagement } from '@/schema';

type Props = {
  language: string;
  site: Site;
  siteConfig: SiteConfig;
  user: UserManagement['currentUser'];
  profile: UserManagement['currentProfile'];
};

const useOpenCommsParams = ({ language, user, profile, siteConfig }: Props) => {
  const [countryInfo, setCountryInfo] = useState<GeolocationIpCountries>();
  const path = usePathname();
  const { isRtl } = getIsRtl();
  const onChatFrameClosed = useCallback(() => {
    global.webkit?.messageHandlers?.moonApp?.postMessage({
      message: 'close-comms-chat',
    });
    global.native?.closeCommsChat();
  }, []);

  useEffect(() => {
    const getCountryInfo = async () => {
      const geoCountryInfo = await getCurrentClientLocation();
      setCountryInfo(geoCountryInfo);
    };
    getCountryInfo();
  }, []);

  const commsPositionCss = {
    left: isRtl ? '20px' : 'auto',
    right: isRtl ? 'auto' : '20px',
  };

  const config = {
    ...siteConfig,
    commsConfig: {
      ...siteConfig.commsConfig,
      styles: {
        button: {
          ...commsPositionCss,
          ...siteConfig.commsConfig.styles?.button,
        },
        frame: { ...commsPositionCss, ...siteConfig.commsConfig.styles?.frame },
      },
    },
  };

  return {
    user,
    profile,
    language,
    countryInfo,
    siteConfig: config,
    isRtl,
    withOpenFrame: path === '/comms',
    onChatFrameClosed: path === '/comms' ? onChatFrameClosed : undefined,
  };
};

export default useOpenCommsParams;
