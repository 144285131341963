'use client';

import DialogController from './DialogController';
import ToastContextProvider from '@/client/components/Toast/Context/ToastContextProvider';

const TwoFADialog: FC<DialogProps> = (props) => (
  <ToastContextProvider>
    <DialogController {...props} />
  </ToastContextProvider>
);

export default TwoFADialog;
