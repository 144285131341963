/* eslint-disable immutable/no-mutation */
import { useEffect } from 'react';
import capitalizeFirstLetter from './capitalizeFirstLetter';

type Args = {
  currentTheme: ColorScheme | undefined;
  site: string;
  isOpen: boolean;
  setClose: () => void;
};

const useCashierTheme = ({ currentTheme, site, isOpen, setClose }: Args) => {
  const siteColorScheme = currentTheme ?? 'light';
  const siteTheme = site + capitalizeFirstLetter(siteColorScheme);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);

  if (!global.Cashier) {
    global.Cashier = {};
  }

  global.Cashier.SiteName = site;
  global.Cashier.SiteTheme = siteTheme;
  global.Cashier.SiteColorScheme = siteColorScheme;
  global.Cashier.Close = setClose;
};

export default useCashierTheme;
