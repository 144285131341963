import { getUpdateABTestCaseMutation } from '../graphql/UpdateCustomAbTestMutation.generated';

const updateCustomAbTest = async () => {
  const customAbTestNeedsUpdating = localStorage.getItem('customAbTest');
  if (!customAbTestNeedsUpdating) {
    return;
  }
  const { data } = await getUpdateABTestCaseMutation({});
  const success = !!data?.testCasesUpdateABTestData?.success;
  if (success) {
    localStorage.removeItem('customAbTest');
  }
};

export default updateCustomAbTest;
