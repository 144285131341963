import Close from '../../Content/Close';

type Props = { onClose: () => void };

const Content: FC<Props> = ({ onClose, children }) => (
  <div
    className="relative grid w-[stretch] gap-4 rounded-moon-s-sm bg-gohan px-8 pb-8 text-center shadow-moon-xl"
    data-testid="nft-reward-notification-container"
  >
    <Close onClose={onClose} />
    {children}
  </div>
);

export default Content;
