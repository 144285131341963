import useCurrencyIcon from './useCurrencyIcon';
import useMoneyValue from './useMoneyValue';

type Options = {
  currency?: CashierCurrency;
  coinInSatoshi?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const useCurrencyDisplay = (
  amount: number,
  language: LanguageCode,
  options?: Options,
  walletCurrency: CashierCurrency = 'BTC',
) => {
  const { currency = walletCurrency } = options ?? {};
  const money =
    useMoneyValue(amount, currency, options ?? {}, language) || '0.00';
  const icon = useCurrencyIcon(currency);
  return {
    money,
    icon,
    moneyIcon: `${money} ${icon}`,
    moneyIconSign: getMoneyIconSign(amount, icon, money),
  };
};

const getMoneyIconSign = (amount: number, icon: string, money?: string) => {
  const sign = amount > 0 ? '+' : '';
  return `${sign} ${money} ${icon}`;
};

export default useCurrencyDisplay;
