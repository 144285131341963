import getInternationalCallingCodes from './getInternationalCallingCodes';

const getCallingCodes = (site: Site) => {
  const primaryOptions: SelectOption[] = getInternationalCallingCodes(
    true,
    site,
  );
  const secondaryOptions: SelectOption[] = getInternationalCallingCodes(
    false,
    site,
  );
  return [...primaryOptions, ...secondaryOptions];
};

export default getCallingCodes;
