// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type GetCurrentClientLocationMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentClientLocationMutation = { __typename?: 'Mutation', geolocationGetCurrentClientLocation?: { __typename?: 'GeolocationGetCurrentClientLocationMutationPayload', country?: { __typename?: 'GeolocationIpCountries', id: string, countryCode?: string, countryName?: string, cityName?: string }, errors?: Array<{ __typename?: 'GeolocationError', message?: string, code?: number, params?: Array<{ __typename?: 'GeolocationErrorParam', name?: string, value?: string }> }> } };


export const GetCurrentClientLocationMutationDocument = gql`
    mutation GetCurrentClientLocationMutation {
  geolocationGetCurrentClientLocation(input: {}) {
    country {
      id
      countryCode
      countryName
      cityName
    }
    errors {
      message
      code
      params {
        name
        value
      }
    }
  }
}
    `;

export const getGetCurrentClientLocationMutation = async (variables: GetCurrentClientLocationMutationVariables) => {
  return fetchGraphql<GetCurrentClientLocationMutation, GetCurrentClientLocationMutationVariables>(GetCurrentClientLocationMutationDocument, "GetCurrentClientLocationMutation", { variables });
}