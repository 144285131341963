import { QRCodeCanvas } from 'qrcode.react';
import classNames from '@/external/classNames';
import getThemeClassName from '@/shared/utils/getThemeClassName';

type Props = { size: number; value: string | number; site: Site };

const QrElement: FC<Props> = ({ value, size, site }) => (
  <div
    className={classNames(
      'relative inline-block bg-goku p-1',
      getThemeClassName(site, 'light'),
    )}
    data-testid="qrcode"
  >
    <QRCodeCanvas
      value={value.toString()}
      size={size}
      fgColor="#000000"
      bgColor="#ffffff"
      includeMargin={false}
      level="L"
    />
  </div>
);

export default QrElement;
