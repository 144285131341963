// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
import { ErrorsFragmentDoc } from '../../../../../../shared/graphql/UpdateUserSettingsMutation.generated';
export type Enable2FAMutationVariables = Types.Exact<{
  otpKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
  otp?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type Enable2FAMutation = { __typename?: 'Mutation', userManagementEnable2FA?: { __typename?: 'UserManagementEnable2FAPayload', clientMutationId?: string, userManagement?: { __typename?: 'UserManagement', id: string, currentProfile?: { __typename?: 'UserManagementProfile', id: string, hasOtp?: boolean } }, errors?: Array<{ __typename?: 'UserManagementError', message?: string, params?: Array<{ __typename?: 'UserManagementErrorParam', name?: string, value?: string }> }> } };


export const Enable2FAMutationDocument = gql`
    mutation Enable2FAMutation($otpKey: String, $otp: String) {
  userManagementEnable2FA(input: {otpKey: $otpKey, otp: $otp}) {
    clientMutationId
    userManagement {
      id
      currentProfile {
        id
        hasOtp
      }
    }
    errors {
      ...ErrorsFragment
    }
  }
}
    ${ErrorsFragmentDoc}`;

export const getEnable2FAMutation = async (variables: Enable2FAMutationVariables) => {
  return fetchGraphql<Enable2FAMutation, Enable2FAMutationVariables>(Enable2FAMutationDocument, "Enable2FAMutation", { variables });
}