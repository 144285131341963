const EVENT_NAME = 'custom-notification-event';

class CustomNotificationEvent {
  listener: EventListener;

  constructor(listener: (data: WorkerNotification) => void) {
    // eslint-disable-next-line immutable/no-mutation
    this.listener = (event) => {
      const { detail } = event as CustomEvent<WorkerNotification>;
      listener(detail);
    };
  }

  subscribe() {
    document.addEventListener(EVENT_NAME, this.listener);
  }

  unsubscribe() {
    document.removeEventListener(EVENT_NAME, this.listener);
  }

  static create(data: WorkerNotification) {
    const event = new CustomEvent(EVENT_NAME, { detail: data });
    document.dispatchEvent(event);
  }
}

export default CustomNotificationEvent;
