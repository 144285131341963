import getCdnBoostImg from '@/shared/cdn/getCdnBoostImg';

const boostCdn = {
  logo: {
    banner: {
      default: getCdnBoostImg('banner-logo-medium-2'),
      sportsbet: getCdnBoostImg('ClubhouseCasinoBoost'),
    },
    mobile: {
      default: getCdnBoostImg('mobile-logo'),
      small: getCdnBoostImg('mobile-logo-small'),
      sportsbet: getCdnBoostImg('sb-mobile-logo'),
      sportsbetSmall: getCdnBoostImg('sb-mobile-logo-small'),
    },
  },
  thunder: {
    big: getCdnBoostImg('big-thunder-yellow'),
    small: getCdnBoostImg('small-thunder'),
  },
  coin: {
    right: getCdnBoostImg('right-coin'),
    left: getCdnBoostImg('left-coin'),
    desktop: {
      right: getCdnBoostImg('new-right-coin', '.webp'),
      left: getCdnBoostImg('new-left-coin', '.webp'),
    },
    mobile: {
      right: getCdnBoostImg('mobile-right-coin', '.webp'),
      left: getCdnBoostImg('mobile-left-coin', '.webp'),
    },
  },
  icon: {
    close: getCdnBoostImg('notification-icon'),
    notification: getCdnBoostImg('golden-boost-bottle', '.webp'),
  },
  confetti: {
    big: getCdnBoostImg('golden-confetti'),
    small: getCdnBoostImg('small-confetti', '.webp'),
    default: getCdnBoostImg('confetti'),
  },
  baruto: {
    logo: getCdnBoostImg('baruto-logo', '.png'),
    smash: {
      banner: getCdnBoostImg('baruto-ground-smash-banner', '.png'),
      full: getCdnBoostImg('baruto-ground-smash-full', '.png'),
    },
  },
  background: {
    desktop: getCdnBoostImg('golden-banner-bg', '.webp'),
    mobile: {
      right1: getCdnBoostImg('gold-pattern-right-1', '.png'),
      right2: getCdnBoostImg('gold-pattern-right-2', '.png'),
      left: getCdnBoostImg('gold-pattern-left', '.png'),
    },
  },
};

export default boostCdn;
