type PhoneCode = {
  value: string;
  code: number;
  title: string;
  primary?: Site[];
  order?: number;
};

type PhoneCallingCodes = Record<string, PhoneCode>;

const internationalCallingCodes: PhoneCallingCodes = {
  FI: { value: 'FI', code: 358, title: 'Finland (+358)' },
  DE: {
    value: 'DE',
    code: 49,
    title: 'Germany (+49)',
    primary: ['bitcasino'],
  },
  NZ: { value: 'NZ', code: 64, title: 'New Zealand (+64)' },
  LI: { value: 'LI', code: 423, title: 'Liechtenstein (+423)' },
  KR: {
    value: 'KR',
    code: 82,
    title: 'Korea South (+82)',
    primary: ['bitcasino'],
  },
  NO: { value: 'NO', code: 47, title: 'Norway (+47)' },
  NL: { value: 'NL', code: 31, title: 'Netherlands (+31)' },
  DK: { value: 'DK', code: 45, title: 'Denmark (+45)' },
  HU: { value: 'HU', code: 36, title: 'Hungary (+36)' },
  LU: { value: 'LU', code: 352, title: 'Luxembourg (+352)' },
  IE: { value: 'IE', code: 353, title: 'Ireland (+353)' },
  AT: { value: 'AT', code: 43, title: 'Austria (+43)' },
  EE: { value: 'EE', code: 372, title: 'Estonia (+372)' },
  SE: { value: 'SE', code: 46, title: 'Sweden (+46)' },
  JP: {
    value: 'JP',
    code: 81,
    title: 'Japan (+81)',
    primary: ['bitcasino'],
  },
  FR: { value: 'FR', code: 33, title: 'France (+33)', primary: ['bitcasino'] },
  BE: { value: 'BE', code: 32, title: 'Belgium (+32)', primary: ['bitcasino'] },
  CA: {
    value: 'CA',
    code: 1,
    title: 'Canada (+1)',
    primary: ['bitcasino'],
  },
  ES: { value: 'ES', code: 34, title: 'Spain (+34)', primary: ['bitcasino'] },
  TH: {
    value: 'TH',
    code: 66,
    title: 'Thailand (+66)',
  },
  MY: {
    value: 'MY',
    code: 60,
    title: 'Malaysia (+60)',
  },
  VN: {
    value: 'VN',
    code: 84,
    title: 'Vietnam (+84)',
  },
  GB: { value: 'GB', code: 44, title: 'England (+44)' },
  US: { value: 'US', code: 1, title: 'US (+1)' },
  DZ: { value: 'DZ', code: 213, title: 'Algeria (+213)' },
  AD: { value: 'AD', code: 376, title: 'Andorra (+376)' },
  AO: { value: 'AO', code: 244, title: 'Angola (+244) ' },
  AI: { value: 'AI', code: 1264, title: 'Anguilla (+1264)' },
  AG: { value: 'AG', code: 1268, title: 'Antigua Barbuda (+1268)' },
  AR: { value: 'AR', code: 54, title: 'Argentina (+54)' },
  AM: { value: 'AM', code: 374, title: 'Armenia (+374)' },
  AW: { value: 'AW', code: 297, title: 'Aruba (+297)' },
  AU: { value: 'AU', code: 61, title: 'Australia (+61)' },
  AZ: { value: 'AZ', code: 994, title: 'Azerbaijan (+994)' },
  BS: { value: 'BS', code: 1242, title: 'Bahamas (+1242)' },
  BH: { value: 'BH', code: 973, title: 'Bahrain (+973)' },
  BD: { value: 'BD', code: 880, title: 'Bangladesh (+880)' },
  BB: { value: 'BB', code: 1246, title: 'Barbados (+1246)' },
  BY: { value: 'BY', code: 375, title: 'Belarus (+375)' },
  BZ: { value: 'BZ', code: 501, title: 'Belize (+501)' },
  BJ: { value: 'BJ', code: 229, title: 'Benin (+229)' },
  BM: { value: 'BM', code: 1441, title: 'Bermuda (+1441)' },
  BT: { value: 'BT', code: 975, title: 'Bhutan (+975)' },
  BO: { value: 'BO', code: 591, title: 'Bolivia (+591)' },
  BA: { value: 'BA', code: 387, title: 'Bosnia Herzegovina (+387)' },
  BW: { value: 'BW', code: 267, title: 'Botswana (+267)' },
  BR: { value: 'BR', code: 55, title: 'Brazil (+55)' },
  BN: { value: 'BN', code: 673, title: 'Brunei (+673)' },
  BG: { value: 'BG', code: 359, title: 'Bulgaria (+359)' },
  BF: { value: 'BF', code: 226, title: 'Burkina Faso (+226)' },
  BI: { value: 'BI', code: 257, title: 'Burundi (+257)' },
  KH: { value: 'KH', code: 855, title: 'Cambodia (+855)' },
  CM: { value: 'CM', code: 237, title: 'Cameroon (+237)' },
  CV: { value: 'CV', code: 238, title: 'Cape Verde Islands (+238)' },
  KY: { value: 'KY', code: 1345, title: 'Cayman Islands (+1345)' },
  CF: { value: 'CF', code: 236, title: 'Central African Republic (+236)' },
  CL: { value: 'CL', code: 56, title: 'Chile (+56)' },
  CN: { value: 'CN', code: 86, title: 'China (+86)' },
  CO: { value: 'CO', code: 57, title: 'Colombia (+57)' },
  KM: { value: 'KM', code: 269, title: 'Comoros (+269)' },
  CG: { value: 'CG', code: 242, title: 'Congo (+242)' },
  CK: { value: 'CK', code: 682, title: 'Cook Islands (+682)' },
  CR: { value: 'CR', code: 506, title: 'Costa Rica (+506)' },
  HR: { value: 'HR', code: 385, title: 'Croatia (+385)' },
  CU: { value: 'CU', code: 53, title: 'Cuba (+53)' },
  CY: { value: 'CY', code: 357, title: 'Cyprus (+357)' },
  CZ: { value: 'CZ', code: 420, title: 'Czech Republic (+420)' },
  DJ: { value: 'DJ', code: 253, title: 'Djibouti (+253)' },
  DM: { value: 'DM', code: 1767, title: 'Dominica (+1767)' },
  DO: { value: 'DO', code: 1, title: 'Dominican Republic (+1)' },
  EC: { value: 'EC', code: 593, title: 'Ecuador (+593)' },
  EG: { value: 'EG', code: 20, title: 'Egypt (+20)' },
  SV: { value: 'SV', code: 503, title: 'El Salvador (+503)' },
  GQ: { value: 'GQ', code: 240, title: 'Equatorial Guinea (+240)' },
  ER: { value: 'ER', code: 291, title: 'Eritrea (+291)' },
  ET: { value: 'ET', code: 251, title: 'Ethiopia (+251)' },
  FK: { value: 'FK', code: 500, title: 'Falkland Islands (+500)' },
  FO: { value: 'FO', code: 298, title: 'Faroe Islands (+298)' },
  FJ: { value: 'FJ', code: 679, title: 'Fiji (+679)' },
  GF: { value: 'GF', code: 594, title: 'French Guiana (+594)' },
  PF: { value: 'PF', code: 689, title: 'French Polynesia (+689)' },
  GA: { value: 'GA', code: 241, title: 'Gabon (+241)' },
  GM: { value: 'GM', code: 220, title: 'Gambia (+220)' },
  GE: { value: 'GE', code: 995, title: 'Georgia (+995)' },
  GH: { value: 'GH', code: 233, title: 'Ghana (+233)' },
  GI: { value: 'GI', code: 350, title: 'Gibraltar (+350)' },
  GR: { value: 'GR', code: 30, title: 'Greece (+30)' },
  GL: { value: 'GL', code: 299, title: 'Greenland (+299)' },
  GD: { value: 'GD', code: 1473, title: 'Grenada (+1473)' },
  GP: { value: 'GP', code: 590, title: 'Guadeloupe (+590)' },
  GU: { value: 'GU', code: 671, title: 'Guam (+671)' },
  GT: { value: 'GT', code: 502, title: 'Guatemala (+502)' },
  GN: { value: 'GN', code: 224, title: 'Guinea (+224)' },
  GW: { value: 'GW', code: 245, title: 'Guinea - Bissau (+245)' },
  GY: { value: 'GY', code: 592, title: 'Guyana (+592)' },
  HT: { value: 'HT', code: 509, title: 'Haiti (+509)' },
  HN: { value: 'HN', code: 504, title: 'Honduras (+504)' },
  HK: { value: 'HK', code: 852, title: 'Hong Kong (+852)' },
  IS: { value: 'IS', code: 354, title: 'Iceland (+354)' },
  IN: { value: 'IN', code: 91, title: 'India (+91)' },
  ID: { value: 'ID', code: 62, title: 'Indonesia (+62)' },
  IR: { value: 'IR', code: 98, title: 'Iran (+98)' },
  IQ: { value: 'IQ', code: 964, title: 'Iraq (+964)' },
  IL: { value: 'IL', code: 972, title: 'Israel (+972)' },
  IT: { value: 'IT', code: 39, title: 'Italy (+39)' },
  JM: { value: 'JM', code: 1, title: 'Jamaica (+1)' },
  JO: { value: 'JO', code: 962, title: 'Jordan (+962)' },
  KZ: { value: 'KZ', code: 7, title: 'Kazakhstan (+7)' },
  KE: { value: 'KE', code: 254, title: 'Kenya (+254)' },
  KI: { value: 'KI', code: 686, title: 'Kiribati (+686)' },
  KP: { value: 'KP', code: 850, title: 'Korea North (+850)' },
  KW: { value: 'KW', code: 965, title: 'Kuwait (+965)' },
  KG: { value: 'KG', code: 996, title: 'Kyrgyzstan (+996)' },
  LA: { value: 'LA', code: 856, title: 'Laos (+856)' },
  LV: { value: 'LV', code: 371, title: 'Latvia (+371)' },
  LB: { value: 'LB', code: 961, title: 'Lebanon (+961)' },
  LS: { value: 'LS', code: 266, title: 'Lesotho (+266)' },
  LR: { value: 'LR', code: 231, title: 'Liberia (+231)' },
  LY: { value: 'LY', code: 218, title: 'Libya (+218)' },
  LT: { value: 'LT', code: 370, title: 'Lithuania (+370)' },
  MO: { value: 'MO', code: 853, title: 'Macao (+853)' },
  MK: { value: 'MK', code: 389, title: 'Macedonia (+389)' },
  MG: { value: 'MG', code: 261, title: 'Madagascar (+261)' },
  MW: { value: 'MW', code: 265, title: 'Malawi (+265)' },
  MV: { value: 'MV', code: 960, title: 'Maldives (+960)' },
  ML: { value: 'ML', code: 223, title: 'Mali (+223)' },
  MT: { value: 'MT', code: 356, title: 'Malta (+356)' },
  MH: { value: 'MH', code: 692, title: 'Marshall Islands (+692)' },
  MQ: { value: 'MQ', code: 596, title: 'Martinique (+596)' },
  MR: { value: 'MR', code: 222, title: 'Mauritania (+222)' },
  YT: { value: 'YT', code: 262, title: 'Mayotte (+262)' },
  MX: { value: 'MX', code: 52, title: 'Mexico (+52)' },
  FM: { value: 'FM', code: 691, title: 'Micronesia (+691)' },
  MD: { value: 'MD', code: 373, title: 'Moldova (+373)' },
  MC: { value: 'MC', code: 377, title: 'Monaco (+377)' },
  MN: { value: 'MN', code: 976, title: 'Mongolia (+976)' },
  MS: { value: 'MS', code: 1664, title: 'Montserrat (+1664)' },
  MA: { value: 'MA', code: 212, title: 'Morocco (+212)' },
  MZ: { value: 'MZ', code: 258, title: 'Mozambique (+258)' },
  MM: { value: 'MM', code: 95, title: 'Myanmar (+95)' },
  NA: { value: 'NA', code: 264, title: 'Namibia (+264)' },
  NR: { value: 'NR', code: 674, title: 'Nauru (+674)' },
  NP: { value: 'NP', code: 977, title: 'Nepal (+977)' },
  NC: { value: 'NC', code: 687, title: 'New Caledonia (+687)' },
  NI: { value: 'NI', code: 505, title: 'Nicaragua (+505)' },
  NE: { value: 'NE', code: 227, title: 'Niger (+227)' },
  NG: { value: 'NG', code: 234, title: 'Nigeria (+234)' },
  NU: { value: 'NU', code: 683, title: 'Niue (+683)' },
  NF: { value: 'NF', code: 672, title: 'Norfolk Islands (+672)' },
  MP: { value: 'MP', code: 670, title: 'Northern Marianas (+670)' },
  OM: { value: 'OM', code: 968, title: 'Oman (+968)' },
  PW: { value: 'PW', code: 680, title: 'Palau (+680)' },
  PA: { value: 'PA', code: 507, title: 'Panama (+507)' },
  PG: { value: 'PG', code: 675, title: 'Papua New Guinea (+675)' },
  PY: { value: 'PY', code: 595, title: 'Paraguay (+595)' },
  PE: { value: 'PE', code: 51, title: 'Peru (+51)' },
  PH: { value: 'PH', code: 63, title: 'Philippines (+63)' },
  PL: { value: 'PL', code: 48, title: 'Poland (+48)' },
  PT: { value: 'PT', code: 351, title: 'Portugal (+351)' },
  PR: { value: 'PR', code: 1, title: 'Puerto Rico (+1)' },
  QA: { value: 'QA', code: 974, title: 'Qatar (+974)' },
  RE: { value: 'RE', code: 262, title: 'Reunion (+262)' },
  RO: { value: 'RO', code: 40, title: 'Romania (+40)' },
  RU: { value: 'RU', code: 7, title: 'Russia (+7)', order: 1 },
  RW: { value: 'RW', code: 250, title: 'Rwanda (+250)' },
  SM: { value: 'SM', code: 378, title: 'San Marino (+378)' },
  ST: { value: 'ST', code: 239, title: 'Sao Tome Principe (+239)' },
  SA: { value: 'SA', code: 966, title: 'Saudi Arabia (+966)' },
  SN: { value: 'SN', code: 221, title: 'Senegal (+221)' },
  CS: { value: 'CS', code: 381, title: 'Serbia (+381)' },
  SC: { value: 'SC', code: 248, title: 'Seychelles (+248)' },
  SL: { value: 'SL', code: 232, title: 'Sierra Leone (+232)' },
  SG: { value: 'SG', code: 65, title: 'Singapore (+65)' },
  SK: { value: 'SK', code: 421, title: 'Slovak Republic (+421)' },
  SI: { value: 'SI', code: 386, title: 'Slovenia (+386)' },
  SB: { value: 'SB', code: 677, title: 'Solomon Islands (+677)' },
  SO: { value: 'SO', code: 252, title: 'Somalia (+252)' },
  ZA: { value: 'ZA', code: 27, title: 'South Africa (+27)' },
  LK: { value: 'LK', code: 94, title: 'Sri Lanka (+94)' },
  SH: { value: 'SH', code: 290, title: 'St. Helena (+290)' },
  KN: { value: 'KN', code: 1869, title: 'St. Kitts (+1869)' },
  LC: { value: 'LC', code: 1758, title: 'St. Lucia (+1758)' },
  SD: { value: 'SD', code: 249, title: 'Sudan (+249)' },
  SR: { value: 'SR', code: 597, title: 'Suriname (+597)' },
  SZ: { value: 'SZ', code: 268, title: 'Swaziland (+268)' },
  CH: { value: 'CH', code: 41, title: 'Switzerland (+41)' },
  SY: { value: 'SY', code: 963, title: 'Syria (+963)' },
  TW: { value: 'TW', code: 886, title: 'Taiwan (+886)' },
  TJ: { value: 'TJ', code: 992, title: 'Tajikstan (+992)' },
  TG: { value: 'TG', code: 228, title: 'Togo (+228)' },
  TO: { value: 'TO', code: 676, title: 'Tonga (+676)' },
  TT: { value: 'TT', code: 1868, title: 'Trinidad Tobago (+1868)' },
  TN: { value: 'TN', code: 216, title: 'Tunisia (+216)' },
  TR: { value: 'TR', code: 90, title: 'Turkey (+90)' },
  TM: { value: 'TM', code: 993, title: 'Turkmenistan (+993)' },
  TC: { value: 'TC', code: 1649, title: 'Turks Caicos Islands (+1649)' },
  TV: { value: 'TV', code: 688, title: 'Tuvalu (+688)' },
  UG: { value: 'UG', code: 256, title: 'Uganda (+256)' },
  UA: { value: 'UA', code: 380, title: 'Ukraine (+380)' },
  AE: { value: 'AE', code: 971, title: 'United Arab Emirates (+971)' },
  UY: { value: 'UY', code: 598, title: 'Uruguay (+598)' },
  UZ: { value: 'UZ', code: 998, title: 'Uzbekistan (+998)' },
  VU: { value: 'VU', code: 678, title: 'Vanuatu (+678)' },
  VA: { value: 'VA', code: 379, title: 'Vatican City (+379)' },
  VE: { value: 'VE', code: 58, title: 'Venezuela (+58)' },
  VG: { value: 'VG', code: 1284, title: 'Virgin Islands - British (+1284)' },
  VI: { value: 'VI', code: 1340, title: 'Virgin Islands - US (+1340)' },
  WF: { value: 'WF', code: 681, title: 'Wallis Futuna (+681)' },
  YE: { value: 'YE', code: 969, title: 'Yemen (North)(+969)' },
  ZM: { value: 'ZM', code: 260, title: 'Zambia (+260)' },
  ZW: { value: 'ZW', code: 263, title: 'Zimbabwe (+263)' },
};

export default internationalCallingCodes;
