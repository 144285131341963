import isUnit from './isUnit';

const getCurrency = (currency: string) => {
  if (isUnit(currency)) {
    return 'BTC';
  }
  return currency;
};

export default getCurrency;
