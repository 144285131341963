import getCdnMoonIcon from '@/shared/cdn/getCdnMoonIcon';
import Img from '@/shared/components/Img';

const SecuritySecurityAttention = () => (
  <Img
    src={getCdnMoonIcon('icons/SecuritySecurityAttention.png')}
    dpr={2}
    alt=""
    width={24}
    height={24}
  />
);

export default SecuritySecurityAttention;
