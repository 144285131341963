import { useEffect, useState } from 'react';
import { getMyBonusOffersNftLinkQuery } from '../graphql/MyBonusOffersNftLinkQuery.generated';

const useMyBonusOffersNftLink = (context: WorkerContext) => {
  const [bonusOfferId, setBonusOfferId] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      const currency = context.currencyConfig.name;
      const result = await getMyBonusOffersNftLinkQuery({
        currency,
      });
      const bonus = result.data?.rewards?.myBonusOffers?.edges?.find(
        (edge) => edge.node?.isNftLink,
      );
      if (bonus?.node?.isNftLink) {
        setBonusOfferId(bonus.node.id);
      }
    };
    fetchData();
  }, [context]);

  return { bonusOfferId };
};

export default useMyBonusOffersNftLink;
