import { useEffect } from 'react';

const useTimeout = <T>(callback: (...args: T[]) => void, ms = 0) => {
  useEffect(() => {
    const timer = setTimeout(callback, ms);
    return () => clearTimeout(timer);
  }, [callback, ms]);
};

export default useTimeout;
