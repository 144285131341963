import getCdnMoonIcon from '@/shared/cdn/getCdnMoonIcon';
import Img from '@/shared/components/Img';

const ArrowsUpdate = () => (
  <Img
    src={getCdnMoonIcon('icons/ArrowsUpdate.png')}
    alt=""
    width={32}
    height={32}
    dpr={2}
  />
);
export default ArrowsUpdate;
