/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

type TimeLeft = {
  days?: number;
  hours?: number;
  minutes: number;
  seconds: number;
};

const getTimeLeft = (
  format: TimerFormat = 'dd/hh:mm:ss',
  timeDiffInMillis = 0,
) => {
  if (format === 'mm:ss') {
    return getTimeLeftInMinutes(timeDiffInMillis);
  }
  return getTimeLeftInDays(timeDiffInMillis);
};

enum Modulus {
  HOUR = 24,
  MINUTE = 60,
  SECOND = 60,
}

const getTimeLeftInDays = (diffInMillis: number): TimeLeft => ({
  days: getDiffInDays(diffInMillis),
  hours: getDiffInHours(diffInMillis, Modulus.HOUR),
  minutes: getDiffInMinutes(diffInMillis, Modulus.MINUTE),
  seconds: getDiffInSeconds(diffInMillis, Modulus.SECOND),
});

const getTimeLeftInMinutes = (diffInMillis: number): TimeLeft => ({
  minutes: getDiffInMinutes(diffInMillis),
  seconds: getDiffInSeconds(diffInMillis, Modulus.SECOND),
});

enum Time {
  DAY = 86400000,
  HOUR = 3600000,
  SECOND = 1000,
}

const getDiffInDays = (diff: number) => floor(diff / Time.DAY);

const getDiffInHours = (diff: number, modulus?: number) =>
  floor(diff / Time.HOUR, modulus);

const getDiffInMinutes = (diff: number, modulus?: number) =>
  floor(diff / Time.SECOND / 60, modulus);

const getDiffInSeconds = (diff: number, modulus?: number) =>
  floor(diff / Time.SECOND, modulus);

const floor = (value: number, modulus?: number) => {
  if (modulus) {
    return Math.floor(value % modulus);
  }
  return Math.floor(value);
};

export default getTimeLeft;
