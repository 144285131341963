'use client';
import Cookies from 'js-cookie';
import LocalStorage from '@/client/utils/storage/LocalStorage';
import SessionStorage from '@/client/utils/storage/SessionStorage';

const closeNotification = (notification: WorkerNotification) => {
  const { id } = notification.data;
  if (!id) {
    return;
  }
  const { dismissStrategy = { storage: 'session' } } = notification;
  switch (dismissStrategy.storage) {
    case 'cookie':
      const { ttl = 0 } = dismissStrategy;
      const expires = new Date(Date.now() + ttl);
      Cookies.set(id, 'true', { expires });
      break;
    case 'session':
      SessionStorage.setItem(id, 'true');
      break;
    case 'local':
      LocalStorage.setItem(id, 'true');
      break;
    default:
      break;
  }
};

export default closeNotification;
