'use client';

import useMarketingTags from './useMarketingTags';

type Props = {
  isLoggedIn: boolean;
  site: Site;
  featureFlags: Map<string, boolean>;
};

const MarketingTagsClient: FC<Props> = ({ isLoggedIn, site, featureFlags }) => {
  useMarketingTags(isLoggedIn, site, featureFlags);

  return null;
};

export default MarketingTagsClient;
