import Translation from '@/client/components/Translation';

type Props = {
  translateId: string;
  translations: Record<string, string>;
};

const Label: FC<Props> = ({ translateId, translations }) => (
  <span className="text-moon-16 uppercase text-trunks">
    <Translation id={translateId} translations={translations} />
  </span>
);

export default Label;
