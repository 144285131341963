import fixLegacyGamePath from '@/client/hooks/fixLegacyGamePath';

const parseNotificationLink = (link = '', isBridgeSite = false) => {
  if (link === '#bonuses') {
    if (isBridgeSite) {
      return '/casino/profile/rewards';
    }

    return '/profile/rewards';
  }
  if (link === '#loyalty') {
    if (isBridgeSite) {
      return '/user/clubhouse';
    }

    return '/profile/loyalty';
  }
  if (link.startsWith('/casino')) {
    if (isBridgeSite) {
      return link.replace('/casino', '/casino/play');
    }
    return fixLegacyGamePath(link);
  }
  return link.replace('#', '/profile/');
};

export default parseNotificationLink;
