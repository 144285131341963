'use client';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';
import Content from '../Content';

const CasinoBoostNotification: FC<NotificationViewProps> = ({
  notification,
  onClose,
  context,
}) => {
  const boost = notification.data;
  return (
    <Content
      onClose={onClose}
      className="bg-gohan"
      testId="casino-boost-notification"
    >
      <Header context={context} />
      <Body boost={boost} context={context} />
      <Footer boost={boost} context={context} onClose={onClose} />
    </Content>
  );
};

export default CasinoBoostNotification;
