'use client';

import Cookies from 'js-cookie';

const useAcqOfferCookies = (site: Site) => {
  const cookies = Cookies.get();
  const acqOfferCookieRegExp = getAcqOfferCookieRegExp(site);
  return cookies
    ? Object.keys(cookies).filter((key) => key.match(acqOfferCookieRegExp))
    : [];
};

const cookiePattern: Record<Site, string> = {
  bitcasino: '^acq_offer\\d{1,3}$',
  sportsbet: '^sb_acq_offer\\d{1,3}$',
  livecasino: 'lc_acq_offer\\d{1,3}$',
  sportsbetmx: '^sbmx_acq_offer\\d{1,3}$',
  empire: '^emp_acq_\\d{1,3}$',
};

const getAcqOfferCookieRegExp = (site: Site) =>
  new RegExp(cookiePattern[site], 'g');

export default useAcqOfferCookies;
