import { useCallback, useEffect, useState } from 'react';
import useClaimNftReward from './useClaimNftReward';
import useMyBonusOffersNftLink from './useMyBonusOffersNftLink';
import handleMarkAsRead from '../../graphql/handleMarkAsRead';
import { getCmsPageQuery } from '@/server/graphql/getCmsPage/CmsPageQuery.generated';
import nativeRedirect from '@/shared/utils/router/nativeRedirect';

type Props = {
  notification: NotificationProps;
  context: WorkerContext;
  onClose: () => void;
};

const useNftRewardNotification = ({
  onClose,
  notification,
  context,
}: Props) => {
  const [content, setContent] = useState<string | null>(null);

  const { bonusOfferId } = useMyBonusOffersNftLink(context);
  const { handleClaimNftReward } = useClaimNftReward();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCmsPageQuery({
        filter: {
          site: context.site,
          language: context.language,
          section: notification.normalNotification?.articleSection,
          handle: notification.normalNotification?.articleHandle,
        },
      });
      const mainBlock =
        result.data?.content?.pages?.edges?.[0]?.node?.mainBlock;
      if (mainBlock) {
        setContent(mainBlock);
      }
    };
    fetchData();
  }, [notification, context]);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await handleMarkAsRead(notification.normalNotification);
    await handleClaimNftReward(bonusOfferId ?? '');
    nativeRedirect('/profile/rewards', context.language, context.language);
    setLoading(false);
    onClose();
  }, [
    notification.normalNotification,
    handleClaimNftReward,
    bonusOfferId,
    context.language,
    onClose,
  ]);

  const handleClose = useCallback(async () => {
    await handleMarkAsRead(notification.normalNotification);
    onClose();
  }, [notification.normalNotification, onClose]);

  return {
    content,
    handleClick,
    handleClose,
    loading,
    bonusOfferId,
  };
};

export default useNftRewardNotification;
