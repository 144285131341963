'use client';
// eslint-disable-next-line no-restricted-imports
import useRewardsNotificationStore from '~/client/components/Game/hooks/useNotificationStore';
import useForfeitReward from './useForfeitReward';
import Content from '../Content';
import useSwitchCurrencyNotification from '../SwitchCurrencyNotification/hooks/useSwitchCurrencyNotification';
import Translation from '@/client/components/Translation';
import classNames from '@/external/classNames';
import Button from '@/external/components/Button';

const FreebetRewardNotificationView: FC<NotificationViewProps> = (props) => {
  const { removeNotification } = useRewardsNotificationStore();
  const { onClose, context, notification } = props;
  const handleClose = () => {
    onClose();
    removeNotification('freeBetReward');
  };

  const { handleForfeit } = useForfeitReward({
    id: notification.data.rewardId,
    onClose: handleClose,
  });
  const { handleCurrencyChange } = useSwitchCurrencyNotification({
    ...props,
    onClose: handleClose,
  });
  const isEmpire = context.site === 'empire';

  return (
    <Content onClose={handleClose}>
      <div className="pt-8 grid gap-8">
        <h4 className="text-moon-18 text-popo font-medium">
          {notification.data.title}
        </h4>
        <p
          className={classNames(
            'text-trunks text-moon-14',
            isEmpire && 'text-popo',
          )}
        >
          {notification.data.body}
        </p>
        <div className="grid gap-4 grid-flow-col grid-cols-[50%_50%]">
          {notification.data.isRequiredToSwitch ? (
            <Button onClick={handleForfeit} className="text-popo bg-goten">
              <Translation
                translations={context.translations}
                id="rewards.forfeit_reward"
              />
            </Button>
          ) : (
            <Button onClick={handleClose} className="text-popo bg-goten">
              <Translation
                translations={context.translations}
                id="general.no"
              />
            </Button>
          )}
          <Button variant="primary" onClick={handleCurrencyChange}>
            <Translation
              translations={context.translations}
              id="button.switch"
            />
          </Button>
        </div>
      </div>
    </Content>
  );
};
export default FreebetRewardNotificationView;
