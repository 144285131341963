import useClientTranslations from '@/client/hooks/useClientTranslations';

const useDataSolutionsNotification = ({ data }: WorkerNotification) => {
  const contentType = data.normalNotification?.contentType ?? 'default';
  const action = `notifications.data_solutions.${contentType}.action`;
  const { title, body, link } = data.normalNotification ?? {};
  const translations = useClientTranslations([action, title, body]);
  return { action, title, body, link, translations };
};

export default useDataSolutionsNotification;
