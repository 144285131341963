type ReturnProps = {
  isRtl: boolean;
  dir: 'rtl' | 'ltr';
};

const getIsRtl = (): ReturnProps => {
  const dir = getLanguageDir('en');
  const isRtl = dir === 'rtl';

  return { isRtl, dir };
};

const rtlLanguageCodes = ['ar', 'fa'];

const getLanguageDir = (code: LanguageCode) =>
  rtlLanguageCodes.includes(code) ? 'rtl' : 'ltr';

export default getIsRtl;
