import bitcasino from './bitcasino';
import empire from './empire';
import livecasino from './livecasino';
import sportsbet from './sportsbet';
import sportsbetmx from './sportsbetmx';

const configs: SiteRecord<SiteConfig> = {
  bitcasino,
  livecasino,
  sportsbet,
  sportsbetmx,
  empire,
};

export default configs;
