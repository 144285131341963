import { createContext } from 'react';

type Props = {
  toastParams?: ToastProps;
  setToastParams: SetState<ToastProps>;
};

export default createContext<Props>({
  toastParams: {
    message: '',
    timeout: 5000,
    position: 'bottom',
    variant: 'success',
  },
  setToastParams: () => undefined,
});
