import { create } from 'zustand';

type Props = {
  notifications: Map<WorkerNotification['type'], string>;
  setNotifications: (type: WorkerNotification['type']) => void;
  removeNotification: (type: WorkerNotification['type']) => void;
  hasNotification: (type: WorkerNotification['type']) => boolean;
};

const useRewardsNotificationStore = create<Props>((set, get) => ({
  notifications: new Map(),
  setNotifications: (type) => {
    set(({ notifications }) => {
      const updatedMap = new Map(notifications).set(type, type);
      return {
        notifications: updatedMap,
      };
    });
  },
  removeNotification: (type) =>
    set(({ notifications }) => {
      const prevState = new Map(notifications);
      prevState.delete(type);
      return {
        notifications: prevState,
      };
    }),
  hasNotification: (type) => get().notifications.has(type),
}));

export default useRewardsNotificationStore;
