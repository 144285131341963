'use client';
import PopupContent from './PopupContent';
import getDeviceType from '@/client/helpers/getDeviceType';
import getCdnBoostImg from '@/shared/cdn/getCdnBoostImg';
import Img from '@/shared/components/Img';
import DeviceType from '@/shared/utils/DeviceType';

const PopupNotification: FC<NotificationViewProps> = ({
  notification,
  onClose,
  context,
}) => {
  const blockedDevices = [
    DeviceType.IOS_PHONE,
    DeviceType.IOS_TABLET,
    DeviceType.WINDOWS_PHONE,
    DeviceType.WINDOWS_TABLET,
  ];

  const deviceType = getDeviceType();
  const { mainBlock } = notification.data;
  const isPopupHidden =
    mainBlock.includes('android-only') && blockedDevices.includes(deviceType);

  if (isPopupHidden) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        data-testid="popup-header"
        className="relative z-50 max-w-[360px] pt-1 px-1 pb-2 md:pb-6 rounded-xl bg-gohan md:max-w-[630px] md:h-fit"
      >
        <div
          className="absolute top-2 right-2 cursor-pointer mt-1 mr-1"
          data-testid="notification-close-button"
          onClick={onClose}
        >
          <Img
            src={getCdnBoostImg('close-notif-icon')}
            height={24}
            width={24}
            alt=""
          />
        </div>
        <PopupContent
          context={context}
          notification={notification}
          onClose={onClose}
        />
      </div>
      <div className="fixed inset-0 bg-black/80" onClick={onClose} />
    </div>
  );
};
export default PopupNotification;
