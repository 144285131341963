/* eslint-disable immutable/no-mutation */
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import getCashierLink from './getCashierLink/getCashierLink';
import useCashierTheme from './useCashierTheme';
import useOpenCloseCashier from './useOpenCloseCashier';
import CommsCommands from '@/client/helpers/CommsCommands';
import ProtoCommands from '@/client/helpers/ProtoCommands';
import useOnClickOutside from '@/client/hooks/useOnClickOutside';
import { CashierCurrencyType } from '@/schema';

type Props = {
  isMobile: boolean;
  cashierPath?: string;
  type: CashierCurrencyType;
  name: CashierCurrency;
  isSbio: boolean;
  currentTheme?: ColorScheme;
  isCashierEnabled: boolean;
  isProtoEnabled: boolean;
  site: Site;
};

const useCashier = ({
  cashierPath = 'home',
  site,
  type,
  name,
  isSbio,
  isCashierEnabled,
  isMobile,
  currentTheme,
  isProtoEnabled,
}: Props) => {
  const { isOpen, handleOpen, handleClose, setOpenStore } = useOpenCloseCashier(
    { cashierPath, type, name },
  );
  const pathname = usePathname() ?? '';
  const params = useSearchParams();
  const isCashierOpen = params?.get('c');
  const link = getCashierLink(pathname, cashierPath, isSbio, isCashierEnabled);

  useEffect(() => {
    window.addEventListener('openCashier', handleOpen);
    return () => {
      window.removeEventListener('openCashier', handleOpen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const protoEL = document.getElementById('sinitic-webchat-wrapper');

    if (isCashierOpen) {
      setOpenStore();
      protoEL?.style.setProperty('z-index', '10001');
    } else {
      protoEL?.style.setProperty('z-index', '49');
    }
  }, [setOpenStore, isCashierOpen]);

  const { elementRef } = useOnClickOutside({
    handler: () => {
      if (!isMobile) {
        handleClose();
      }
    },
    allowCommsClick: true,
  });

  useCashierTheme({ site, setClose: handleClose, isOpen, currentTheme });

  if (global.Cashier) {
    global.Cashier.OpenComms = () => {
      if (isProtoEnabled) {
        global.Cashier?.Close?.();
        ProtoCommands.open();
      } else {
        CommsCommands.show({});
      }
    };
  }

  return { isOpen, link, handleOpen, handleClose, elementRef };
};

export default useCashier;
