import { useState } from 'react';
import QrElement from '@/client/components/QrElement';
import Translation from '@/client/components/Translation';
import classNames from '@/external/classNames';
import IconButton from '@/external/components/IconButton';
import SecurityQrCode from '@/external/icons/SecurityQrCode';

type Props = {
  site: Site;
  domain: string;
  otpKey: string;
  translations: Record<string, string>;
};

const AppAuth: FC<Props> = ({ site, domain, otpKey, translations }) => {
  const [showQrCode, setShowQrCode] = useState(false);
  return (
    <div
      className={classNames(
        'rounded-xl border-beerus text-center',
        showQrCode && 'pt-12',
      )}
    >
      {showQrCode && (
        <QrElement
          value={`otpauth://totp/${domain}?secret=${otpKey}`}
          size={170}
          site={site}
        />
      )}
      <div
        className={classNames(
          'align-center -m-px flex justify-between gap-3 rounded-xl bg-gohan py-4 pl-6 pr-4',
          showQrCode && 'mt-12',
        )}
      >
        <div className="grid justify-items-start gap-0">
          <p className="text-moon-14 font-bold text-bulma">
            <Translation
              id="profile.ga_secret_title"
              translations={translations}
            />
          </p>
          <p className="break-all text-left text-moon-14 font-bold leading-4 text-trunks">
            {otpKey}
          </p>
        </div>
        <IconButton
          className={classNames(
            'flex h-7 w-7 items-center justify-center rounded bg-transparent p-0',
            showQrCode && 'bg-piccolo/10',
          )}
          icon={
            <SecurityQrCode
              className={classNames(
                'text-moon-24 text-bulma',
                showQrCode && 'text-piccolo',
              )}
            />
          }
          onClick={() => setShowQrCode(!showQrCode)}
          data-testid="show-qr-button"
        />
      </div>
    </div>
  );
};
export default AppAuth;
