// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
export type ForfeitFSRewardMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ForfeitFSRewardMutation = { __typename?: 'Mutation', rewardsForfeitReward?: { __typename?: 'RewardsForfeitRewardPayload', rewards?: { __typename?: 'Rewards', id: string }, errors?: Array<{ __typename?: 'RewardsError', code?: number, message?: string, params?: Array<{ __typename?: 'RewardsErrorParam', name?: string, value?: string }> }> } };


export const ForfeitFSRewardMutationDocument = gql`
    mutation ForfeitFSRewardMutation($id: String!) {
  rewardsForfeitReward(input: {id: $id}) {
    rewards {
      id
    }
    errors {
      code
      message
      params {
        name
        value
      }
    }
  }
}
    `;

export const getForfeitFSRewardMutation = async (variables: ForfeitFSRewardMutationVariables) => {
  return fetchGraphql<ForfeitFSRewardMutation, ForfeitFSRewardMutationVariables>(ForfeitFSRewardMutationDocument, "ForfeitFSRewardMutation", { variables });
}