import getCdnBoostImg from '@/shared/cdn/getCdnBoostImg';
import Img from '@/shared/components/Img';

type Props = { onClose: () => void };

const CloseButton: FC<Props> = ({ onClose }) => (
  <div
    className=" right-4 top-4 cursor-pointer"
    data-testid="notification-close-button"
    onClick={onClose}
  >
    <Img
      src={getCdnBoostImg('close-notif-icon')}
      alt=""
      height={24}
      width={24}
    />
  </div>
);

export default CloseButton;
