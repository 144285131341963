import { SetStateAction } from 'react';
import Snackbar from '@/external/components/Snackbar';
import GenericCheckRounded from '@/external/icons/GenericCheckRounded';

type Props = {
  isOpen: boolean;
  onOpenChange:
    | ((value: SetStateAction<boolean>) => void)
    | ((value: boolean) => void);
  message?: string;
  variant: ToastProps['variant'];
};

const Toast: FC<Props> = ({ isOpen, onOpenChange, message }) => (
  // TODO: add variants
  <Snackbar
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    position="bottom-left"
    className="min-w-44 rounded-moon-s-md bg-bulma"
    autoClose={3000}
  >
    <Snackbar.Icon>
      <GenericCheckRounded />
    </Snackbar.Icon>
    <Snackbar.Message className="text-goku">{message}</Snackbar.Message>
  </Snackbar>
);

export default Toast;
