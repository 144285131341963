import internationalCallingCodes from './internationalCallingCodes';
import phonesSorter from './phonesSorter';

const getInternationalCallingCodes = (primary: boolean, site: Site) => {
  const keys = Object.keys(internationalCallingCodes);
  const list = keys.reduce((acc: SelectOption[], key: string) => {
    const item = internationalCallingCodes[key];
    const primarySites = item.primary;
    const isValidCallingCode = primary
      ? primarySites && primarySites.includes(site)
      : !primarySites || !primarySites.includes(site);

    if (isValidCallingCode) {
      return [
        ...acc,
        { label: `${key} (+${item.code})`, value: item.value, title: key },
      ];
    }

    return acc;
  }, []);

  return list.sort(phonesSorter);
};

export default getInternationalCallingCodes;
