import type { ReactElement } from 'react';
import GenericDislike from '@/external/icons/GenericDislike';
import GenericLike from '@/external/icons/GenericLike';
import GenericTrophy from '@/external/icons/GenericTrophy';
import SecurityPassport from '@/external/icons/SecurityPassport';
import SecuritySecurityAttention from '@/external/icons/SecuritySecurityAttention';
import SecurityShieldSecured from '@/external/icons/SecurityShieldSecured';
import ShopWallet from '@/external/icons/ShopWallet';

type NotificationContent = {
  icon: ReactElement;
  text: string;
  action: string;
};

const kycWarningIconUrl = <SecuritySecurityAttention />;
const kycVerifyWithdrawUrl = <ShopWallet />;
const kycVerificationRequired = <SecurityPassport />;
const kycVerificationCompelete = <SecurityShieldSecured />;
const kycVerificationFailed = <GenericDislike />;
const kycVerificationReview = <GenericLike />;
const kycReward = <GenericTrophy />;

const getKycNotificationContent = (lastStatus: string): NotificationContent => {
  switch (lastStatus) {
    case 'forced_kyc_process_started':
      return {
        icon: kycVerificationRequired,
        text: 'forced_kyc_process_started',
        action: 'verify',
      };
    case 'threshold_1':
      return {
        icon: kycVerificationRequired,
        text: 'threshold_1',
        action: 'verify',
      };
    case 'threshold_1_reminder_1':
    case 'threshold_1_reminder_2':
    case 'threshold_1_reminder_3':
    case 'threshold_1_reminder_4':
    case 'threshold_1_reminder_5':
      return {
        icon: kycReward,
        text: 'threshold_1_reminder_1',
        action: 'verify',
      };
    case 'threshold_2':
      return {
        icon: kycWarningIconUrl,
        text: 'threshold_2',
        action: 'verify',
      };
    case 'threshold_2_reminder_1':
      return {
        icon: kycVerifyWithdrawUrl,
        text: 'threshold_2_reminder_1',
        action: 'verify',
      };
    case 'threshold_2_reminder_2':
      return {
        icon: kycWarningIconUrl,
        text: 'threshold_2_reminder_2',
        action: 'verify',
      };
    case 'threshold_2_reminder_3':
      return {
        icon: kycVerifyWithdrawUrl,
        text: 'threshold_2_reminder_3',
        action: 'verify',
      };
    case 'threshold_3':
      return {
        icon: <SecurityPassport />,
        text: 'threshold_3.modal.title',
        action: 'verify',
      };
    case 'kyc_processing':
      return {
        icon: kycVerificationReview,
        text: 'kyc_submitted',
        action: 'close',
      };
    case 'kyc_accepted':
      return {
        icon: kycVerificationCompelete,
        text: 'kyc_accepted',
        action: 'close',
      };
    case 'kyc_failed':
      return {
        icon: kycVerificationFailed,
        text: 'kyc_failed',
        action: 'resubmit',
      };
    case 'kyc_expired':
      return {
        icon: kycVerificationRequired,
        text: 'kyc_expired',
        action: 'verify',
      };
    case 'id_expiry_reminder_1':
      return {
        icon: kycVerificationRequired,
        text: 'id_expiry_reminder_1',
        action: 'verify',
      };
    case 'id_expiry_reminder_2':
      return {
        icon: kycVerificationRequired,
        text: 'id_expiry_reminder_2',
        action: 'verify',
      };
    case 'id_expired':
      return {
        icon: kycVerificationRequired,
        text: 'id_expired',
        action: 'verify',
      };
    default:
  }
  return {
    icon: kycWarningIconUrl,
    text: lastStatus,
    action: 'verify',
  };
};

export default getKycNotificationContent;
