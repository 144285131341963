import useCurrencyDigits from './useCurrencyDigits';
import useParsedDisplayAmount from './useParsedDisplayAmount';
import formatFloat from '@/shared/utils/getDisplayAmount/helpers/formatFloat';

type Options = {
  coinInSatoshi?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

const useMoneyValue = (
  amount: number,
  currency: CashierCurrency | string,
  options: Options,
  language: LanguageCode,
) => {
  const { maximumFractionDigits } = options;
  const effectiveCurrency = currency as CashierCurrency;
  const digits = useCurrencyDigits(maximumFractionDigits);
  const parsedAmount = useParsedDisplayAmount({
    amount,
    currency: effectiveCurrency,
  });
  const money = formatFloat(
    parsedAmount,
    language,
    maximumFractionDigits ?? digits.max,
  );
  return money;
};

export default useMoneyValue;
