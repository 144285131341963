const transformExperiments = (
  rawExperiments: Record<string, { variation_id: string }>,
) =>
  Object.keys(rawExperiments).reduce(
    (acc, cur) => {
      // eslint-disable-next-line immutable/no-mutation
      acc[cur] = rawExperiments[cur].variation_id;
      return acc;
    },
    {} as Record<string, string>,
  );

export default transformExperiments;
