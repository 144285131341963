import useResendCodeTimer from './useResendCodeTimer';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';
import ArrowsUpdate from '@/external/icons/ArrowsUpdate';

type Props = {
  onResendCodeClick: () => void;
  translations: Record<string, string>;
};

const ResendCodeButton: FC<Props> = ({ onResendCodeClick, translations }) => {
  const { handleResendClick, timeLeftFormatted, isTimeOver } =
    useResendCodeTimer(onResendCodeClick);

  return (
    <Button
      iconLeft={<ArrowsUpdate />}
      variant="secondary"
      fullWidth
      size="lg"
      onClick={handleResendClick}
      disabled={!isTimeOver}
      data-testid="resend-button"
    >
      {!isTimeOver && (
        <p className="text-moon-14 text-trunks">
          <Translation id="profile.resend_in" translations={translations} />
          {timeLeftFormatted}
        </p>
      )}
      {isTimeOver && (
        <p>
          <Translation
            id="profile.send_code_again"
            translations={translations}
          />
        </p>
      )}
    </Button>
  );
};

export default ResendCodeButton;
