import { useCallback, useEffect } from 'react';
import useAcqOfferCookies from './useAcqOfferCookies';
import { getToggleTagMutation } from '../graphql/ToggleTagMutation.generated';
import deleteCookie from '@/shared/utils/cookie/deleteCookie';
import isSsr from '@/shared/utils/isSsr';

const useAcqOfferTags = (isLoggedIn: boolean, site: Site) => {
  const marketingCookies = useAcqOfferCookies(site);

  const setAcqOfferTags = useCallback(async () => {
    marketingCookies.forEach(async (tag) => {
      const payload = await getToggleTagMutation({ tag, value: true });
      const { isTagEnabled } = payload.data?.featuresToggleTag ?? {};
      if (isTagEnabled) {
        deleteCookie(tag);
      }
    });
  }, [marketingCookies]);

  useEffect(() => {
    if (!isSsr() && isLoggedIn && marketingCookies.length) {
      setAcqOfferTags();
    }
  }, [isLoggedIn, marketingCookies, setAcqOfferTags]);
};

export default useAcqOfferTags;
