/* eslint-disable immutable/no-mutation */
import { createRoot } from 'react-dom/client';
import NotificationView from './NotificationView';

const showNotification = ({
  notification,
  context,
  onClose,
}: NotificationViewProps) => {
  if (document.getElementById('notification-container')) {
    return;
  }
  const container = document.createElement('div');
  container.id = 'notification-container';
  document.body.appendChild(container);
  const root = createRoot(container);
  const handleClose = () => {
    root.unmount();
    document.body.removeChild(container);
    onClose();
  };
  root.render(
    <NotificationView
      notification={notification}
      context={context}
      onClose={handleClose}
    />,
  );
};

export default showNotification;
