import replaceVariables from '@/shared/utils/translations/replaceVariables';

type Props = {
  id: string;
  translations: Record<string, Maybe<string>>;
  values?: { [key: string]: string | number };
  className?: string;
};

const Translation: FC<Props> = (props) => {
  const translation = getTranslation(props);
  return (
    <span data-translation={props.id} className={props.className}>
      {translation}
    </span>
  );
};

const getTranslation = ({ id, translations, values }: Props) => {
  const translation = translations[id] ?? id;
  if (!values) {
    return translation;
  }
  return replaceVariables(translation, values);
};

export default Translation;
