import EnableAppAuthDialog from './EnableAppAuthDialog';
import EnableSmsAuthDialog from './EnableSmsAuthDialog';
import use2faModes from './hooks/use2faModes';
import MethodSelectionPopup from './MethodSelectionPopup';

type Props = {
  onClose: () => void;
  initialMode?: Mode;
  location: string;
  translations: Record<string, string>;
  isMobile: boolean;
  site: Site;
  countryCode: string;
  domain: string;
  isSms2faSwitcherEnabled?: boolean;
};

const Modes: FC<Props> = ({
  onClose,
  initialMode,
  translations,
  isMobile,
  site,
  domain,
  location,
  countryCode,
  isSms2faSwitcherEnabled,
}) => {
  const { mode, showMethodSelection, showSms2fa, showApp2fa, handleClickBack } =
    use2faModes(initialMode, isSms2faSwitcherEnabled);
  switch (mode) {
    case 'methodSelection': {
      return (
        <MethodSelectionPopup
          selectSms2fa={showSms2fa}
          selectApp2fa={showApp2fa}
          translations={translations}
          onClose={onClose}
          isMobile={isMobile}
        />
      );
    }
    case 'app2fa':
      return (
        <EnableAppAuthDialog
          onClickBack={handleClickBack}
          onClose={onClose}
          site={site}
          domain={domain}
          translations={translations}
          isMobile={isMobile}
        />
      );
    case 'sms2fa':
      return (
        <>
          <EnableSmsAuthDialog
            onUseAppClick={() => {
              showApp2fa();
            }}
            onClose={onClose}
            onClickBack={showMethodSelection}
            isMobile={isMobile}
            translations={translations}
            language={location}
            site={site}
            countryCode={countryCode}
            domain={domain}
          />
        </>
      );
    // TODO: add for profile page
    // case 'app2faDisable':
    //   return (
    //     <DisableAppAuthDialog
    //       onSuccess={onAppAuthDisableSuccess}
    //       onClose={onClose}
    //     />
    //   );
    // case 'disableAuthAppFirst':
    //   return (
    //     <DisableOther2FA
    //       onClose={onClose}
    //       onSubmit={showApp2faDisable}
    //       method="app"
    //     />
    //   );
    // case 'disableSms2faFirst':
    //   return (
    //     <DisableOther2FA
    //       onClose={onClose}
    //       onSubmit={showSms2faDisable}
    //       method="sms"
    //     />
    //   );
    // case 'sms2faDisable':
    //   return (
    //     <DisableSmsAuthDialog
    //       onClose={onClose}
    //       onSuccess={onSmsAuthDisableSuccess}
    //     />
    //   );
    default:
      return null;
  }
};

export default Modes;
