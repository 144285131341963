/* eslint-disable no-restricted-imports */
'use client';
import parse from 'html-react-parser';
import CloseButton from './CloseButton';
import useOpenCloseCashier from '@/client/components/CashierWrap/useCashier/useOpenCloseCashier';
import sendToGtm from '@/client/helpers/sendToGtm';
import classNames from '@/external/classNames';
import Button from '@/external/components/Button';

const LowBalanceNotification: FC<NotificationViewProps> = ({
  onClose,
  notification,
}) => {
  const translationButton = notification.data.body;
  const translationTitle = notification.data.title;
  const { handleOpen: openCashier } = useOpenCloseCashier({
    type: 'crypto',
    name: 'BTC',
    cashierPath: 'deposit',
  });

  const handleClaimClick = () => {
    sendToGtm({
      event: 'lowbalanceNotification',
      eventCategory: 'deposit_button',
      eventAction: 'lowbalance_notification',
    });
    openCashier();
  };

  return (
    <div
      data-testid="low-balance-notification"
      className={classNames(
        'relative grid w-[stretch] gap-4 rounded-moon-s-sm bg-goten px-6 py-5 text-left shadow-moon-xl md:w-96 mb-7 md:mb-0',
      )}
    >
      <CloseButton onClose={onClose} />
      <div>{parse(translationTitle)}</div>
      <Button onClick={handleClaimClick} className="w-24 md:flex hidden">
        {translationButton}
      </Button>
    </div>
  );
};

export default LowBalanceNotification;
