import useClaimButton from './useClaimButton';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';

type Props = {
  boost: BoostTimerFragment;
  fullWidth?: boolean;
  translations: Record<string, string>;
  onClose: () => void;
};

const ClaimButton: FC<Props> = ({
  boost,
  fullWidth,
  translations,
  onClose,
}) => {
  const { disabled, handleClick: claim } = useClaimButton(boost);
  const handleClick = () => {
    claim();
    onClose();
  };
  return (
    <Button
      className="z-10"
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={handleClick}
    >
      <Translation id="casino.boosts.claim_now" translations={translations} />
    </Button>
  );
};

export default ClaimButton;
