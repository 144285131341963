import Dialog from '../Dialog';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';
import DevicesSmartphone from '@/external/icons/DevicesSmartphone';
import SecurityQrCode from '@/external/icons/SecurityQrCode';

type Props = {
  selectApp2fa: () => void;
  selectSms2fa: () => void;
  onClose: () => void;
  translations: Record<string, string>;
  isMobile: boolean;
};

const MethodSelectionPopup: FC<Props> = ({
  selectApp2fa,
  selectSms2fa,
  onClose,
  translations,
  isMobile,
}) => (
  <Dialog
    onClose={onClose}
    title="profile.2fa"
    translations={translations}
    isMobile={isMobile}
  >
    <p className="text-trunks">
      <Translation
        id="profile.choose_authentication_method"
        translations={translations}
      />
    </p>
    <div className="grid gap-2">
      <Button
        variant="secondary"
        iconLeft={<SecurityQrCode className="text-moon-32" />}
        fullWidth
        onClick={selectApp2fa}
        size="lg"
        data-testid="select-app-2fa"
      >
        <p className="text-moon-16">
          <Translation
            id="profile.app_authentication"
            translations={translations}
          />
        </p>
      </Button>
      <Button
        iconLeft={<DevicesSmartphone className="text-moon-32" />}
        variant="secondary"
        fullWidth
        onClick={selectSms2fa}
        size="lg"
        data-testid="select-sms-2fa"
      >
        <p className="text-moon-16">
          <Translation
            id="profile.sms_authentication"
            translations={translations}
          />
        </p>
      </Button>
    </div>
  </Dialog>
);

export default MethodSelectionPopup;
