import { useState, useEffect } from 'react';
import formatNumber from './helpers/formatNumber';
import useResendingEnableTimeCookie from './useResendingEnableTimeCookie';
import useTimer from './useTimer';

const getTimeLeft = (resendingEnableTimeCookie: number) => {
  const timePassed = (resendingEnableTimeCookie - new Date().getTime()) / 1000;
  return timePassed > 0 ? timePassed : 0;
};

const useResendCodeTimer = (onResendCodeClick: () => void) => {
  const { resendingEnableTimeCookie, setResendingEnableTimeCookie } =
    useResendingEnableTimeCookie();

  const [secondsLeft, setSecondsLeft] = useState(
    getTimeLeft(resendingEnableTimeCookie),
  );

  const timeLeft = useTimer({ format: 'mm:ss', secondsLeft });

  const handleResendClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onResendCodeClick();
    setSecondsLeft(0);
    setResendingEnableTimeCookie();
  };
  const isTimeOver = timeLeft.minutes + timeLeft.seconds === 0;

  useEffect(() => {
    if (!isTimeOver) {
      return;
    }
    setSecondsLeft(getTimeLeft(resendingEnableTimeCookie));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSecondsLeft, resendingEnableTimeCookie]);

  const timeLeftFormatted = `${formatNumber(timeLeft.minutes)}:${formatNumber(
    timeLeft.seconds,
  )}`;

  return {
    handleResendClick,
    timeLeftFormatted,
    isTimeOver,
  };
};

export default useResendCodeTimer;
