import BoostMoney from './BoostMoney';

type Props = {
  amount?: number;
  currency?: CashierCurrency;
  language: LanguageCode;
};

const Amount: FC<Props> = ({ amount, currency, language }) => (
  <div className="font-headline text-moon-24 font-bold text-bulma">
    <BoostMoney amount={amount} currency={currency} language={language} />
  </div>
);

export default Amount;
