// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
import { ErrorsFragmentDoc } from '../../../../../../shared/graphql/UpdateUserSettingsMutation.generated';
export type StartEnablingSMS2FAMutationVariables = Types.Exact<{
  phone?: Types.InputMaybe<Types.Scalars['String']['input']>;
  language?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type StartEnablingSMS2FAMutation = { __typename?: 'Mutation', userManagementStartEnablingSMS2FA?: { __typename?: 'UserManagementStartEnablingSMS2FAPayload', clientMutationId?: string, userManagement?: { __typename?: 'UserManagement', id: string, currentProfile?: { __typename?: 'UserManagementProfile', id: string, hasSMS2FA?: boolean } }, errors?: Array<{ __typename?: 'UserManagementError', message?: string, params?: Array<{ __typename?: 'UserManagementErrorParam', name?: string, value?: string }> }> } };


export const StartEnablingSMS2FAMutationDocument = gql`
    mutation StartEnablingSMS2FAMutation($phone: String, $language: String) {
  userManagementStartEnablingSMS2FA(input: {phone: $phone, language: $language}) {
    clientMutationId
    userManagement {
      id
      currentProfile {
        id
        hasSMS2FA
      }
    }
    errors {
      ...ErrorsFragment
    }
  }
}
    ${ErrorsFragmentDoc}`;

export const getStartEnablingSMS2FAMutation = async (variables: StartEnablingSMS2FAMutationVariables) => {
  return fetchGraphql<StartEnablingSMS2FAMutation, StartEnablingSMS2FAMutationVariables>(StartEnablingSMS2FAMutationDocument, "StartEnablingSMS2FAMutation", { variables });
}