// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../../../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
import { ErrorsFragmentDoc } from '../../../../../../shared/graphql/UpdateUserSettingsMutation.generated';
export type FinishEnablingSMS2FAMutationVariables = Types.Exact<{
  otp?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type FinishEnablingSMS2FAMutation = { __typename?: 'Mutation', userManagementFinishEnablingSMS2FA?: { __typename?: 'UserManagementFinishEnablingSMS2FAPayload', clientMutationId?: string, userManagement?: { __typename?: 'UserManagement', id: string, currentProfile?: { __typename?: 'UserManagementProfile', id: string, hasSMS2FA?: boolean } }, errors?: Array<{ __typename?: 'UserManagementError', message?: string, params?: Array<{ __typename?: 'UserManagementErrorParam', name?: string, value?: string }> }> } };


export const FinishEnablingSMS2FAMutationDocument = gql`
    mutation FinishEnablingSMS2FAMutation($otp: String) {
  userManagementFinishEnablingSMS2FA(input: {otp: $otp}) {
    clientMutationId
    userManagement {
      id
      currentProfile {
        id
        hasSMS2FA
      }
    }
    errors {
      ...ErrorsFragment
    }
  }
}
    ${ErrorsFragmentDoc}`;

export const getFinishEnablingSMS2FAMutation = async (variables: FinishEnablingSMS2FAMutationVariables) => {
  return fetchGraphql<FinishEnablingSMS2FAMutation, FinishEnablingSMS2FAMutationVariables>(FinishEnablingSMS2FAMutationDocument, "FinishEnablingSMS2FAMutation", { variables });
}