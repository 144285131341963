import Button from '@/external/components/Button';

const TwoFANotification: FC<NotificationViewProps> = ({
  onClose,
  notification,
}) => {
  const translations = notification.data.translations;
  const handleEnableClick = () => {
    window.dispatchEvent(new CustomEvent('openTwoFA'));
    onClose();
  };
  return (
    <div
      data-testid="2fa-notification"
      className="grid w-[stretch] gap-4 rounded-moon-s-md bg-gohan p-4 text-left shadow-moon-md rtl:text-right md:w-80 md:p-6"
    >
      <div className="grid gap-2">
        <h4 className="text-moon-16 font-medium text-bulma">
          {translations['profile.make_your_account_more_secure']}
        </h4>
        <p
          className="text-moon-16 text-bulma"
          dangerouslySetInnerHTML={{
            __html: translations['profile.turn_on_2fa'],
          }}
        />
        <div className="mt-3 flex flex-row justify-end">
          <Button
            onClick={onClose}
            className="bg-transparent text-moon-16 text-trunks -my-2"
          >
            {translations['general.cancel']}
          </Button>
          <Button
            onClick={handleEnableClick}
            className="h-auto bg-transparent -mx-4 -my-2"
          >
            <p className="text-moon-16 text-piccolo">
              {translations['general.enable']}
            </p>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default TwoFANotification;
