import getCdnMoonIcon from '@/shared/cdn/getCdnMoonIcon';
import Img from '@/shared/components/Img';

const SecurityPassport = () => (
  <Img
    src={getCdnMoonIcon('icons/SecurityPassport.png')}
    dpr={2}
    alt=""
    width={32}
    height={32}
  />
);

export default SecurityPassport;
