const phonesSorter = (prev: SelectOption, next: SelectOption) => {
  if (next.title) {
    if (!prev.title || (prev.title && next.title > prev.title)) {
      return -1;
    }
    return 1;
  }
  const prevValue = prev.title?.toLowerCase() || '';
  const nextValue = next.title?.toLowerCase() || '';
  if (prevValue > nextValue) {
    return 1;
  }
  return -1;
};

export default phonesSorter;
