'use client';
import { useEffect, useState } from 'react';
import Modes from './Modes';

const DialogController: FC<DialogProps> = ({
  translations,
  isMobile,
  location,
  site,
  domain,
  isSms2faSwitcherEnabled,
  coutryCode,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    window.addEventListener('openTwoFA', () => {
      setOpen(true);
    });
  }, []);
  if (!open) {
    return null;
  }
  return (
    <Modes
      onClose={handleClose}
      location={location}
      translations={translations}
      isMobile={isMobile}
      site={site}
      domain={domain}
      isSms2faSwitcherEnabled={isSms2faSwitcherEnabled}
      countryCode={coutryCode}
    />
  );
};

export default DialogController;
