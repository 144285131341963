import Close from '../../Content/Close';

type Props = { onClose: () => void; hiddenCloseBtn?: boolean };

const Content: FC<Props> = ({ onClose, hiddenCloseBtn, children }) => (
  <div className="relative grid w-[stretch] gap-4 rounded-moon-s-sm bg-goten px-8 pb-8 text-center shadow-moon-xl md:w-80 overflow-hidden">
    {!hiddenCloseBtn && <Close onClose={onClose} />}
    {children}
  </div>
);

export default Content;
