import getCdnMoonIcon from '@/shared/cdn/getCdnMoonIcon';
import Img from '@/shared/components/Img';

const SecurityShieldSecured = () => (
  <Img
    src={getCdnMoonIcon('icons/SecurityShieldSecured.png')}
    dpr={2}
    alt=""
    width={24}
    height={24}
  />
);

export default SecurityShieldSecured;
