'use client';
import CloseButton from './CloseButton';
import Translation from '@/client/components/Translation';
import getLanguage from '@/client/utils/getLanguage';
import classNames from '@/external/classNames';
import getLanguageDir from '@/shared/utils/getLanguageDir';

const OptInNotificationView: FC<NotificationViewProps> = ({
  onClose,
  context,
}) => {
  const lang = getLanguage();
  const dir = getLanguageDir(lang);
  const isRtl = dir === 'rtl';
  return (
    <div
      data-testid="optin-notification"
      className={classNames(
        'relative grid w-[-webkit-fill-available] cursor-pointer gap-4 rounded-moon-i-sm bg-bulma p-4 text-left shadow-moon-xs md:pointer-events-auto md:w-80',
        isRtl && 'text-right',
      )}
      onClick={onClose}
    >
      <p className="text-gohan">
        <Translation
          id="promotion.optin_notification"
          translations={context.translations}
        />
      </p>
      <CloseButton onClose={onClose} />
    </div>
  );
};
export default OptInNotificationView;
