'use client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { rem } from 'polished';
// eslint-disable-next-line no-restricted-imports
import getEnv from '~/shared/utils/getEnv';
import useOpenCommsParams from './useOpenCommsParams';
import getCommsConfig from './utils/getCommsConfig';
import getIsRtl from './utils/getIsRtl';
import useBottomNavigationStore from '@/client/components/BottomProfile/useBottomNavigationStore';
import CommsCommands from '@/client/helpers/CommsCommands';
import useCashierStore from '@/client/hooks/useCashierStore';
import { UserManagement } from '@/schema';

type Props = {
  language: string;
  site: Site;
  user: UserManagement['currentUser'];
  profile: UserManagement['currentProfile'];
  siteConfig: SiteConfig;
  isMobile?: boolean;
  isBottomNavigation?: boolean;
};

const CommsScript = ({
  language,
  site,
  user,
  profile,
  siteConfig,
  isBottomNavigation,
  isMobile,
}: Props) => {
  const { isRtl } = getIsRtl();
  const commsUrl = getEnv('COMMS_URL') ?? 'https://cdn.comms.io/sdk/comms.js';
  const asPath = usePathname();
  const isCommsPage = asPath?.includes('/comms');
  const commsParams = useOpenCommsParams({
    language,
    site,
    user,
    profile,
    siteConfig,
  });
  const config = getCommsConfig({ ...commsParams });
  const fsSessionUrl = global.FS?.getCurrentSessionURL?.();
  const [isFSLoaded, setIsFSLoaded] = useState(!!fsSessionUrl);
  const isBottomN = isBottomNavigation && isMobile;
  const { isMainOpened } = useBottomNavigationStore();
  const bottomPosition = useMemo(
    () => isBottomN && { bottom: rem(80) },
    [isBottomN],
  );
  const isDisplay = useMemo(
    () =>
      isMainOpened && isBottomN ? { display: 'none' } : { display: 'flex' },
    [isMainOpened, isBottomN],
  );
  const rtlStyle = useMemo(
    () => isRtl && { left: rem(20), right: 'auto' },
    [isRtl],
  );

  const commsStyles = useMemo(
    () => ({
      styles: {
        ...config.styles,
        button: {
          ...bottomPosition,
          ...rtlStyle,
          ...config.styles?.button,
          ...isDisplay,
        },
      },
    }),
    [bottomPosition, rtlStyle, config, isDisplay],
  );
  const { isOpen } = useCashierStore();

  useEffect(() => {
    if (!isFSLoaded && fsSessionUrl) {
      CommsCommands.update({ args: { ...config, ...commsStyles } });
      setIsFSLoaded(true);
    }
    if (!isOpen) {
      CommsCommands.update({ args: { ...config, ...commsStyles } });
    }
  }, [fsSessionUrl, config, isFSLoaded, commsStyles, isOpen]);

  const initComms = useCallback(() => {
    // eslint-disable-next-line immutable/no-mutation
    global.commsQueue = global.commsQueue || [];

    CommsCommands.init({
      args: {
        ...config,
        ...commsStyles,
      },
    });
    if (isCommsPage) {
      CommsCommands.show({});
    }
  }, [config, commsStyles, isCommsPage]);

  return (
    <Script
      id="comms-script"
      strategy="lazyOnload"
      onLoad={initComms}
      src={commsUrl}
    />
  );
};

export default CommsScript;
