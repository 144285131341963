import { useState } from 'react';
import { getFinishEnablingSMS2FAMutation } from './FinishEnablingSMS2FAMutation.generated';

const useCodeEntering = (
  onSubmit: () => void,
  translations: Record<string, string>,
) => {
  const [error, setError] = useState<string>();

  const handleSubmit = async (otp: string) => {
    const result = await getFinishEnablingSMS2FAMutation({
      otp,
    });
    const { errors: mutationErrors } =
      result.data?.userManagementFinishEnablingSMS2FA || {};
    if (mutationErrors?.length) {
      setError(`errors.${mutationErrors[0].message}`);
      return;
    }
    onSubmit();
  };

  const handleChange = (value: string) => {
    setError('');
    if (value.length === 6) {
      handleSubmit(value);
    }
  };

  const errorMessage =
    error && translations.hasOwnProperty(error) ? translations[error] : error;

  return { handleSubmit, errorMessage, handleChange };
};

export default useCodeEntering;
