import boostCdn from '../boostCdn';
import classNames from '@/external/classNames';
import Img from '@/shared/components/Img';

type Props = {
  context: WorkerContext;
};

const Header: FC<Props> = ({ context }) => {
  const isBitcasino = context.site === 'bitcasino';
  return (
    <div className="relative h-24">
      <Img
        width={216}
        height={256}
        alt=""
        className={classNames(
          'absolute -top-10 left-1/2 -translate-x-1/2',
          isBitcasino && '-top-20',
        )}
        src={isBitcasino ? boostCdn.confetti.big : boostCdn.confetti.default}
      />
      <Img
        width={96}
        height={103}
        alt=""
        className="absolute left-1/2 top-1 w-24 -translate-x-1/2"
        src={boostCdn.icon.notification}
      />
    </div>
  );
};

export default Header;
