import sportsbet from './sportsbet';

const sportsbetmx: SiteConfig = {
  ...sportsbet,
  name: 'Sportsbet Mexico',
  domain: 'sportsbet.mx',
  refCode: 'sbmxseo',
  gtmId: 'GTM-59RTN6W',
  commsConfig: {
    ...sportsbet.commsConfig,
    productToken: '2999E290EA411ACAFB514E5E9C9E03E6C018DBDC',
  },
  seo: {
    ...sportsbet.seo,
    og: {
      ...sportsbet.seo.og,
      image:
        'https://sportsbet-io.imgix.net/sportsbet-io/favicons/sportsbetmx-logo-vertical-1200x1200.png',
    },
  },
};

export default sportsbetmx;
