'use client';

import Content from './Content';
import useNftRewardNotification from './hooks/useNftRewardNotification';
import Translation from '@/client/components/Translation';
import useClientTranslations from '@/client/hooks/useClientTranslations';
import Button from '@/external/components/Button';

type Props = {
  notification: NotificationProps;
  context: WorkerContext;
  onClose: () => void;
};

const NftRewardNotificationView: FC<Props> = ({
  notification,
  onClose,
  context,
}) => {
  const { handleClose, content, handleClick, bonusOfferId, loading } =
    useNftRewardNotification({
      notification,
      context,
      onClose,
    });
  const translations = useClientTranslations(['general.claim']) || {};
  if (!content || !bonusOfferId) {
    return null;
  }

  return (
    <div className="fixed inset-x-4 bottom-[calc(10rem+env(safe-area-inset-bottom,0))] sm:bottom-[calc(20%+env(safe-area-inset-bottom,0))] lg:left-1/3 lg:right-1/3">
      <Content onClose={handleClose}>
        <div className="grid gap-2 pt-6 ltr:text-left rtl:text-right">
          <div
            className="max-h-[calc(100vh-21.25rem)] overflow-auto scroll-hidden"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="mt-2 flex justify-end">
            <Button
              variant="primary"
              onClick={handleClick}
              disabled={loading}
              animation={loading ? 'progress' : undefined}
            >
              <Translation translations={translations} id={'general.claim'} />
            </Button>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default NftRewardNotificationView;
