'use client';
import { useCallback } from 'react';
import Cookies from 'js-cookie';
import Script from 'next/script';
import decodeToken from '@/client/helpers/decodeToken';
import getCdnResource from '@/shared/cdn/getCdnResource';

type Props = {
  isLoggedIn: boolean;
  cid: string;
  backUrl: string;
};

const init = async (isLoggedIn: boolean, cid: string, backUrl: string) => {
  try {
    window.gib.init({
      cid,
      backUrl,
      gafUrl: '//eu.id.group-ib.com/id.html',
    });

    window.gib.setAuthStatus(
      isLoggedIn ? window.gib.IS_AUTHORIZED : window.gib.IS_GUEST,
    );
    if (isLoggedIn) {
      const token = Cookies.get('token');
      if (token) {
        const decodedToken = decodeToken(token);
        window.gib.setSessionID(decodedToken.sessionId);
        window.gib.setIdentity(decodedToken.userId);
      }
    }
  } catch (e) {}
};

const SnippetInitializer: FC<Props> = ({ isLoggedIn, cid, backUrl }) => {
  const onLoadScript = useCallback(() => {
    if (window.gib) {
      init(isLoggedIn, cid, backUrl);
    } else {
      // eslint-disable-next-line immutable/no-mutation
      window.onGIBLoad = () => {
        init(isLoggedIn, cid, backUrl);
      };
    }
  }, [backUrl, cid, isLoggedIn]);
  return (
    <Script
      id="group-ib_snippet"
      strategy="lazyOnload"
      src={getCdnResource('bitcasino/js/group-ib_snippet.js')}
      onLoad={onLoadScript}
    />
  );
};

export default SnippetInitializer;
