import type { HTMLAttributes } from 'react';

type Props = {
  message?: string;
  domain: string;
  translations: Record<string, string>;
} & HTMLAttributes<HTMLParagraphElement>;

const FormError: FC<Props> = ({
  message = '',
  domain,
  translations,
  ...htmlProps
}) => {
  const formatMessage = message.startsWith('error.')
    ? message.replace('error.', 'errors.')
    : message;
  const domainTranslation = translations.hasOwnProperty(formatMessage)
    ? translations[formatMessage].replace('bitcasino.io', domain)
    : formatMessage;
  return (
    <p
      className="mt-2 inline-block text-moon-12 text-chichi"
      role="alert"
      {...htmlProps}
    >
      {domainTranslation}
    </p>
  );
};

export default FormError;
