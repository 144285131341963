import React, { FC } from 'react';
import classNames from '@/external/classNames';
import ControlsCloseSmall from '@/external/icons/ControlsCloseSmall';
import getCdnResource from '@/shared/cdn/getCdnResource';
import Img from '@/shared/components/Img';

type Props = {
  onClose: () => void;
  currentTheme: ColorScheme | undefined;
};

const CashierLoadingSkeleton: FC<Props> = ({ onClose, currentTheme }) => (
  <div className="relative h-full w-full p-6">
    <div className="absolute left-6 top-6 z-10000" onClick={onClose}>
      <ControlsCloseSmall />
    </div>
    <Img
      className={classNames(currentTheme !== 'dark' && '[filter:invert(1)]')}
      src={getCdnResource(`bitcasino/images/cashierLoad.svg`)}
      width={500}
      height={500}
      alt="cashierLoader"
    />
  </div>
);

export default CashierLoadingSkeleton;
