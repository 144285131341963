import { useCallback } from 'react';
import type { ExecutionResult } from 'graphql';
import Cookies from 'js-cookie';
import {
  ToggleTagMutation,
  getToggleTagMutation,
} from '../graphql/ToggleTagMutation.generated';

type Args = {
  tag:
    | 'freespins_acq_offer'
    | 'acq_offer'
    | 'reactoonz_FS'
    | 'RiseofMerlin_FS'
    | 'MoonPrinces_FS';
  cookie:
    | 'freespinsAcqOffer'
    | 'acq_offer'
    | 'reactoonz_FS'
    | 'RiseofMerlin_FS'
    | 'MoonPrinces_FS';
  featureFlags: Map<string, boolean>;
};

const useSetMarketingTag = ({ tag, cookie, featureFlags }: Args) => {
  const shouldSetTag = useCallback(() => {
    const hasTag = featureFlags.get(tag as Feature);
    return !hasTag && Cookies.get(cookie);
  }, [cookie, featureFlags, tag]);

  const setTag = useCallback(async () => {
    if (!shouldSetTag()) {
      return;
    }
    const payload = await getToggleTagMutation({
      tag,
      value: true,
    });
    processSetTagResult(payload, cookie);
  }, [shouldSetTag, tag, cookie]);
  return [setTag];
};

const processSetTagResult = (
  payload: ExecutionResult<ToggleTagMutation>,
  cookie: string,
) => {
  if (!payload.data) {
    return;
  }

  const { errors } = payload.data.featuresToggleTag ?? {};
  if (!errors) {
    Cookies.remove(cookie);
  }
};

export default useSetMarketingTag;
