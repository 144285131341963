import bitcasino from './bitcasino';
import { CASINO_IMGIX, CDN_IMG_URL } from '@/shared/cdn/url';

const sportsbet: SiteConfig = {
  ...bitcasino,
  name: 'Sportsbet',
  domain: 'sportsbet.io',
  defaultColorScheme: 'dark',
  iconSetCdnPath: `${CDN_IMG_URL}/icons`,
  iconSetExt: '.svg',
  refCode: 'sbseo',
  fontTypes: ['Averta Std'],
  privacyPolicyLink: '/about/privacy-policy',
  gtmId: 'GTM-MCBZFMC',
  symplifyId: '5620484',
  pathPrefix: '/casino',
  isBridgeSite: true,
  menuItemsConfig: {
    common: {
      first: 12,
      filterName: '',
    },
  },
  commsConfig: {
    productId: 9,
    productToken: '1E1783230B6759325733391912DF0064D15721A0',
    theme: {
      chatButtonColor: '#0CD463',
      chatButtonColorActive: '#0CD463',
      buttonColor: '#0CD463',
      buttonColorActive: '#0CD463',
      chatBubbleColor: '#0CD463',
      notificationColor: '#FF4E64',
      notificationTextColor: '#FFFFFF',
    },
  },
  smartlookConfig: {
    isScriptEnabled: false,
    projectId: '',
  },
  convertComConfig: {
    isScriptEnabled: true,
    url: 'https://cdn-4.convertexperiments.com/v1/js/10042537-10048779.js',
  },
  protoConfig: {
    gwId: 'gw_UWVQAh7qlz9MSDHYjj1jd5Q',
  },
  umamiScript: true,
  icons: {
    icon: [
      {
        url: `https://${CASINO_IMGIX}/bitcasino/images/sportsbet/favicons/favicon.ico`,
        sizes: '32x32',
        type: 'image/png',
      },
    ],
    apple: [
      {
        url: `https://${CASINO_IMGIX}/bitcasino/images/sportsbet/favicons/apple-touch-icon-180x180.png`,
        sizes: '180x180',
      },
    ],
  },
  themeColor: '#0CD664',
  seo: {
    title: 'general.title',
    description: 'general.meta_description',
    og: {
      title: 'og.title',
      description: 'og.description',
      image: `https://${CASINO_IMGIX}/bitcasino/images/logo-sportsbet.png`,
    },
  },
};

export default sportsbet;
