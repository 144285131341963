import sendToGtm from '@/client/helpers/sendToGtm';

const initUserGtmEvents = () => {
  const { user, isLoggedIn, isVip, locale } = global.GTM ?? {};
  sendToGtm({ event: 'locale', pageLocale: locale });
  sendToGtm({
    event: 'userData',
    userId: user?.userId,
    userName: user?.username,
    userLoginStatus: isLoggedIn,
    isUserVip: isVip,
  });
};

export default initUserGtmEvents;
