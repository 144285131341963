import React, { useCallback } from 'react';
import handleMarkAsRead from '../../graphql/handleMarkAsRead';
import parseNotificationLink from '../../helpers/parseNotificationLink';
import Translation from '@/client/components/Translation';
import Button from '@/external/components/Button';
import configs from '@/server/helpers/getSiteConfig/configs';
import nativeRedirect from '@/shared/utils/router/nativeRedirect';

type Props = {
  notification: WorkerNotification['data'];
  action: string;
  onClose: () => void;
  translations: Record<string, string>;
  context: WorkerContext;
};

const ActionButton: FC<Props> = ({
  notification,
  action,
  onClose,
  translations,
  context,
}) => {
  const handleClick = useCallback(async () => {
    await handleMarkAsRead(notification);
    onClose();
    const { isBridgeSite, defaultLang } = configs[context.site];
    const parsedLink = parseNotificationLink('/profile/account', isBridgeSite);
    nativeRedirect(parsedLink, context.language, defaultLang);
  }, [context, notification, onClose]);

  const handleNotificationClick = useCallback(async () => {
    await handleMarkAsRead(notification);
    onClose();
  }, [notification, onClose]);

  if (action === 'close') {
    return (
      <div
        className="grid cursor-pointer grid-flow-col items-center gap-4 text-moon-14 font-medium text-piccolo"
        onClick={handleNotificationClick}
      >
        <Translation id={'general.close'} translations={translations} />
      </div>
    );
  }
  const actionTranslationId =
    action === 'verify'
      ? 'notifications.kyc.verify_button'
      : 'notifications.kyc.resubmit_button';

  const variant = action === 'verify' ? 'primary' : 'secondary';

  return (
    <Button variant={variant} onClick={handleClick} data-testid="verify-button">
      <Translation translations={translations} id={actionTranslationId} />
    </Button>
  );
};

export default ActionButton;
