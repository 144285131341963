'use client';
import CasinoBoostNotification from './CasinoBoostNotification';
import CoinSportNotification from './CoinSportNotification';
import DataSolutionsNotification from './DataSolutionsNotification';
import FreebetRewardNotificationView from './FreebetRewardNotificationView';
import isCoinSportNotification from './helpers/isCoinSportNotification';
import KycNotification from './KycNotification';
import LowBalanceNotification from './LowBalanceNotification';
import LoyaltyNotification from './LoyaltyNotification';
import NftRewardNotification from './NftRewardNotification';
import NormalNotification from './NormalNotification';
import OptInNotificationView from './OptInNotificationView';
import PopupNotification from './PopupNotification';
import SwitchCurrencyNotification from './SwitchCurrencyNotification';
import TwoFANotification from './TwoFANotification';
import useTimeout from '@/client/hooks/useTimeout';
import classNames from '@/external/classNames';

const MAX_TIMEOUT = 2147483647;
const DEFAULT_TIMEOUT = 10000000000;
const infinityTypes = ['FreebetsReward', 'DataSolutionsNotification'];

const NotificationView: FC<NotificationViewProps> = ({
  notification,
  context,
  onClose,
}) => {
  useTimeout(
    () => {
      if (notification.sticky) {
        return;
      }
      onClose();
    },
    notification.data?.duration ??
      (infinityTypes.includes(notification.data?.type ?? '')
        ? MAX_TIMEOUT
        : DEFAULT_TIMEOUT),
  );
  const Component = getViewComponent(notification);
  if (!Component) {
    onClose();
    return null;
  }
  return (
    <div
      className={classNames(
        'fixed inset-x-4 z-50 grid max-w-md gap-2 sm:bottom-[calc(2.5rem+env(safe-area-inset-bottom,0))]',
        'sm:ltr:left-10 sm:ltr:right-0 sm:rtl:left-0 sm:rtl:right-10',
        'bottom-[calc(10rem+env(safe-area-inset-bottom,0))]',
      )}
    >
      <Component
        notification={notification}
        context={context}
        onClose={onClose}
      />
    </div>
  );
};

const getViewComponent = (notification: WorkerNotification) => {
  const notificationType = isCoinSportNotification(notification.data)
    ? 'coinSport'
    : notification.type;

  switch (notificationType) {
    case '2FA':
      return TwoFANotification;
    case 'loyaltyTier':
    case 'loyaltyMilestone':
      return LoyaltyNotification;
    case 'boost':
      return CasinoBoostNotification;
    case 'kyc':
      return KycNotification;
    case 'nft':
      return NftRewardNotification;
    case 'site':
    case 'normal':
      return NormalNotification;
    case 'optin':
      return OptInNotificationView;
    case 'dataSolutions':
      return DataSolutionsNotification;
    case 'currency':
      return SwitchCurrencyNotification;
    case 'lowbalance':
      return LowBalanceNotification;
    case 'freeBetReward':
      return FreebetRewardNotificationView;
    case 'coinSport':
      return CoinSportNotification;
    case 'popup':
      return PopupNotification;
    default:
      return undefined;
  }
};

export default NotificationView;
